import styled from 'styled-components';

import { Carousel } from 'react-responsive-carousel';

import { NoPhotos } from '../../../../../../components/icons';

import theme from '../../../../../../constants/theme';

export const StyledCarousel = styled(Carousel)<{isCarouselOverlay?: boolean}>`
  height: ${props => !props.isCarouselOverlay ? '320px' : 'auto'};

  .carousel {
    display: flex;
    flex-direction: row;

    .slider-wrapper {
      border-radius: 5px;
    }
  }

  img {
    height: ${props => !props.isCarouselOverlay ? '320px' : 'auto'};
    object-fit: cover;

    @media (max-width: 425px) {
      height: ${props => !props.isCarouselOverlay ? '192px' : 'auto'};
    }
  }
`;

export const ArrowLeft = styled.button`
  margin-right: 0.5rem;

  background: none;

  font-size: 24px;
  color: ${theme.colors.blue.mid};

  @media (max-width: 425px) {
    display: none;
  }
`;

export const ArrowRight = styled.button`
  margin-left: 0.5rem;

  background: none;

  font-size: 24px;
  color: ${theme.colors.blue.mid};

  @media (max-width: 425px) {
    display: none;
  }
`;

export const NoInvoice = styled.div`
  width: 100%;
  padding: 0.8rem;

  color: #bdbdbd;
  background: white;

  border-radius: 4px;
  border: 1px solid ${theme.colors.grey.mid};

  display: flex;
  align-items: center;
`;

export const Counter = styled.div`
  display: flex;
  margin-top: 0.9rem;
  justify-content: center;
  color: ${theme.colors.grey.darkness_mid};
`;

export const NoPhotosIcon = styled(NoPhotos)`
  height: 25px;
  margin-right: 0.5rem;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;
