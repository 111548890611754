import { FC, useState } from 'react';
import { format, parse } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import {
  ArrowStyled,
  ChildItem,
  Description,
  ListContainer,
  ListItem,
  NFNumber,
  StatusTag,
  StatusText,
  Title,
  TruckStyled,
} from './styles';
import { statusTag } from './tags';

import { STEP_COLUMN } from '../../../../../constants/stepTracking';
import ConvertSteps from '../../../../../utils/ConvertSteps';

interface CardItemProps {
  item: any;
}

const removeBrackets = (value: string) => {
  return value
    .replace(/[\[\]']+/g, '')
    .replace(/,/g, ', ')
    .replace(/[{[]}]/g, '');
};

const CardItem: FC<CardItemProps> = ({ item }) => {
  const [ stepCod ] = useState(ConvertSteps(item.status_codigo)); 
  const navigate = useNavigate();

  const renderTitleDate = (current: number) => {
    switch (current) {
      case STEP_COLUMN.CREATED:
      case STEP_COLUMN.PREPARED:
      case STEP_COLUMN.IN_TRANSIT:
      case STEP_COLUMN.ON_THE_WAY:
        return 'Previsão';
      case STEP_COLUMN.DELIVERED:
      case STEP_COLUMN.PARTIALLY_DELIVERED:
        return 'Entrega';
      case STEP_COLUMN.CANCELED_DELIVERED:
        return 'Cancelado';
      case STEP_COLUMN.DENIED_DELIVERED:
        return 'Recusado';
      default:
        return 'Previsão';
    }
  }

  return (
    <ListItem key={item} onClick={() => navigate(`/detail/${item.Numero_CTE_TMS}`)}>
      <ListContainer>
        <ChildItem>
          <NFNumber>
            CTE {item.Numero_CTE_TMS ? item.Numero_CTE_TMS : '-'}
          </NFNumber>
          <Description>
            {item.DataHora_TMS
              ? format(parse(item.DataHora_TMS, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
              : '-'}
          </Description>
        </ChildItem>
        <ChildItem>
          <Title>Remetente</Title>
          <Description>
            {item.Emitente_TMS ? item.Emitente_TMS : '-'}
          </Description>
        </ChildItem>
        <ChildItem>
          <Title>Notas</Title>
          <Description>{item.isNotas
              ? item.isNotas
                  .replace(/,/g, ', ')
                  .replace(/[\[\]']+/g, '')
                  .replace(/[{[]}]/g, '')
              : '-'}</Description>
        </ChildItem>
      </ListContainer>
      <ListContainer>
        <StatusTag status={stepCod}>
          <TruckStyled status={stepCod} />
          <StatusText status={stepCod}>
            {statusTag(stepCod)}
          </StatusText>
        </StatusTag>
        <ChildItem>
          <Title>
            {renderTitleDate(stepCod)}
          </Title>
          <Description>
            {item.DtPrevisao_TMS
              ? format(parse(item.DtPrevisao_TMS, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
              : '-'}
          </Description>
        </ChildItem>

        <ArrowStyled />
      </ListContainer>
    </ListItem>
  );
};

export default CardItem;
