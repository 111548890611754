import { FC, ReactNode } from 'react';
import { ButtonProps as ButtonANTDProps } from 'antd';

import Spinner from '../Spinner';
import { StyledButton, OutlineButton, ActionButton } from './styles';

interface ButtonProps extends ButtonANTDProps {
  children: ReactNode;
  isLoading?: boolean;
  mode?: 'default' | 'outline' | 'action';
  leftElement?: ReactNode;
  rightElement?: ReactNode;
}

const Button: FC<ButtonProps> = ({
  isLoading = false,
  children,
  mode,
  leftElement,
  rightElement,
  ...rest
}) => {
  return (
    <>
      {mode === 'default' && (
        <StyledButton {...rest} loading={isLoading}> {children} </StyledButton>
      )}

      {mode === 'outline' && (
        <OutlineButton {...rest} loading={isLoading}> {children} </OutlineButton>
      )}

      {mode === 'action' && (
        <ActionButton {...rest} loading={isLoading}>
          {leftElement && leftElement}
          {children}
          {rightElement && rightElement}
        </ActionButton>
      )}
    </>
  );
};

export default Button;
