/* eslint-disable no-self-compare */
import { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import CardItem from './CardItem';

import { EmptyContent, ListStyled, LoaderContent, WrapperEmpty } from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IInvoice, useInvoice } from '../../../../contexts/invoicesContext';

interface CardListProps {
  loading: boolean;
  data: Array<IInvoice>;
  count: number;
  handleGetCTEs: (filter?: string) => void;
}

const CardList: FC<CardListProps> = ({ data, count, loading, handleGetCTEs }) => {
  return loading ? (
    <EmptyContent>
      <Spin indicator={<LoadingOutlined spin />} />
    </EmptyContent>
  ) : data.length <= 0 ? (
    <WrapperEmpty>
      <span>Nenhuma entrega para exibir :(</span>
    </WrapperEmpty>
  ) : (
    <InfiniteScroll
      dataLength={data.length}
      next={handleGetCTEs}
      hasMore={data.length < count}
      loader={
        data.length > 4 && (
          <LoaderContent>
            <Spin indicator={<LoadingOutlined spin />} />
          </LoaderContent>
        )
      }
      scrollThreshold={0.96}
    >
      <ListStyled
        dataSource={data}
        renderItem={item => <CardItem item={item} />}
        amount={data.length <= 3}
      />
    </InfiniteScroll>
  );
};

export default CardList;
