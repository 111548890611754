import { STEP_COLUMN } from '../../../../../constants/stepTracking';

export const statusTag = (status: number) => {
  switch (status) {
    case STEP_COLUMN.CREATED:
      return 'Criado';
    case STEP_COLUMN.PREPARED:
      return 'Preparado';
    case STEP_COLUMN.IN_TRANSIT:
      return 'Em trânsito';
    case STEP_COLUMN.ON_THE_WAY:
      return 'Indo até você';
    case STEP_COLUMN.DELIVERED:
      return 'Entregue';
    case STEP_COLUMN.PARTIALLY_DELIVERED:
      return 'Parcialmente Entregue';
    case STEP_COLUMN.DENIED_DELIVERED:
      return 'Entrega Recusada';
    case STEP_COLUMN.CANCELED_DELIVERED:
      return 'Entrega Cancelada';
    default:
      return '-';
  }
};

export const statusBg = (status: number) => {
  switch (status) {
    case STEP_COLUMN.CREATED:
    case STEP_COLUMN.PREPARED:
    case STEP_COLUMN.IN_TRANSIT:
      return ({ theme }: any) => theme.colors.blue.light;
    case STEP_COLUMN.ON_THE_WAY:
      return ({ theme }: any) => theme.colors.blue.midle;
    case STEP_COLUMN.DELIVERED:
      return ({ theme }: any) => theme.colors.green.mid;
    case STEP_COLUMN.PARTIALLY_DELIVERED:
      return ({ theme }: any) => theme.colors.orange.mid;
    case STEP_COLUMN.DENIED_DELIVERED:
    case STEP_COLUMN.CANCELED_DELIVERED:
      return ({ theme }: any) => theme.colors.red.mid;
    default:
      return ({ theme }: any) => theme.colors.blue.midle;
  }
};

export const statusInner = (status: number) => {
  switch (status) {
    case STEP_COLUMN.CREATED:
    case STEP_COLUMN.PREPARED:
    case STEP_COLUMN.IN_TRANSIT:
      return ({ theme }: any) => theme.colors.blue.dark;
    default:
      return ({ theme }: any) => theme.colors.white;
  }
};