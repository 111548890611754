import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '../../../../components/container';
import Layout from '../../../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  WelcomeContainer,
} from './styles';

const Register = () => {
  const [animated, setAnimated] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setAnimated(true);
    setTimeout(() => {
      navigate('/form');
    }, 500);
  };

  return (
    <Layout mode={"external"}>
      <WelcomeContainer>
        <Container>
          <Content animated={animated}>
            <HeadingStyled type="h1">Tracking Luft</HeadingStyled>
            <HeadingStyled type="h2">
              Parece que é sua primeira vez por aqui.
            </HeadingStyled>

            <Description>
              Para garantir a segurança das informações, antes de acessar a
              posição da sua nota, precisamos que você confirme alguns dados para
              termos certeza de que você é você.
            </Description>

            <ButtonStyled mode="default" onClick={handleChange}>
              Vamos lá
            </ButtonStyled>
          </Content>
        </Container>
      </WelcomeContainer>
    </Layout>
  );
};

export default Register;
