import styled from 'styled-components';
import { Form } from 'formik';
import { Progress } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';

import Container from '../../components/container';
import Button from '../../components/button';
import Heading from '../../components/heading';
import Text from '../../components/text';

export const ConfirmationWrapper = styled.div`
  height: calc(100vh - 10.3em);
  display: flex;
  
  padding-top: 20px;
`;

export const ContainerStyled = styled(Container)``;

export const Content = styled.div`
  width: 48vw;
  max-width: 504px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    width: 100%;
    max-width: unset;
  `}
`;

export const FormWrapper = styled.div`
  min-height: calc(100vh - 28em);
  max-height: calc(100vh - 16em);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const GoBackButton = styled(Button)`
  padding-bottom: 24px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-moz-scrollbar {
    display: none;
  }
`;

export const HeaderRegister = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 30px;
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

export const TimerWrapper = styled.div`
  background: ${({ theme }) => theme.colors.blue.darkness_rgba};
  border-radius: 4px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const TimerShift = styled.div`
  width: 54px;
  height: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const TimerText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 12px;
  `}
`;

export const TimerIcon = styled(FieldTimeOutlined)`
  color: ${({ theme }) => theme.colors.white};
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const ButtonStyled = styled(Button)`
  height: 48px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Resendwrapper = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ResendText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`;

export const ResentButton = styled(Button)``;

export const ConfirmContainer = styled.div`
  height: calc(100vh - 20rem);
`;

export const ProgressStyled = styled(Progress)`
  span {
    color: ${({ theme }) => theme.colors.white};
  }
`;
