import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  ContainerStyled,
} from '../styles';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Layout mode={"external"}>
      <ContainerStyled>
        <Content>
          <HeadingStyled type="h1">Página não encontrada</HeadingStyled>

          <Description>
            Parece que a página que você está tentando acessar não existe ou não
            está mais disponível.
          </Description>

          <ButtonStyled mode="default" onClick={() => navigate('/')}>
            Ir para a página inicial
          </ButtonStyled>
        </Content>
      </ContainerStyled>
    </Layout>
  );
};

export default NotFound;
