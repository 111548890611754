import React, { createContext, useState, useContext, Dispatch } from 'react';

interface ContactInfos {
  email?: string;
  phone?: string;
}

interface NavigationData {
  contact: ContactInfos | null;
  setContact: Dispatch<React.SetStateAction<ContactInfos | null>>;

  resetToken: string | null;
  setResetToken: Dispatch<React.SetStateAction<string | null>>;

  nf: string | null;
  setNf: Dispatch<React.SetStateAction<string | null>>;
}

const NavigationContext = createContext<NavigationData>({} as NavigationData);

const NavigationProvider: React.FC = ({ children }) => {  
  const [contact, setContact] = useState<ContactInfos | null>(null);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [nf, setNf] = useState<string | null>(null);

  return (
    <NavigationContext.Provider value={{ contact, setContact, resetToken, setResetToken, nf, setNf }}>
      {children}
    </NavigationContext.Provider>
  );
};

function useNavigationInfos(): NavigationData {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigationInfos must be used within an NavigationProvider');
  }
  
  return context;
}

export { NavigationProvider, useNavigationInfos };