import api from '../../providers/axios';
import { AxiosRequestConfig } from 'axios';

import {
  IConfirmRegisterRequest,
  IConfirmRegisterResponse,
} from './dtos/IConfirmRegister';

import {
  IValidationTokenRequest,
  IValidationTokenResponse,
} from './dtos/IValidationToken';

import {
  IResetValidationTokenRequest,
  IResetValidationTokenResponse,
} from './dtos/IResetValidationToken';

import {
  ITrackInvoiceSessionRequest,
  ITrackInvoiceSessionResponse,
} from './dtos/ITrackInvoiceSession';

export default class SessionService {

  public async ConfirmRegister(data: IConfirmRegisterRequest, config?: AxiosRequestConfig): Promise<IConfirmRegisterResponse> {
    return await api.post('/confirmation', data, config);
  }

  public async TrackInvoiceSession (data: ITrackInvoiceSessionRequest): Promise<ITrackInvoiceSessionResponse> {
    const response = await api.post('/nf/session', data);

    return response.data;
  }

  public async ValidationToken (data: IValidationTokenRequest, config?: AxiosRequestConfig): Promise<IValidationTokenResponse> {
    const response = await api.post('/session/token', data, config);

    return response.data;
  }

  public async ValidationTokenAuth (): Promise<IValidationTokenResponse> {
    const response = await api.get('/session/token');

    return response.data;
  }

  public async ResetValidationToken (data: IResetValidationTokenRequest): Promise<IResetValidationTokenResponse> {
    const response = await api.patch('/session/token', data);

    return response.data;
  }
}
