import { FC } from 'react';

import { BoxWrapper, MessageText } from './styles';

interface BoxProps {
  label: string;
}

const BoxAlert: FC<BoxProps> = ({ label }) => {
  return (
    <BoxWrapper>
      <MessageText>{label}</MessageText>
    </BoxWrapper>
  );
};

export default BoxAlert;
