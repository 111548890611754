import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

import * as Yup from 'yup';

import { Formik, FormikErrors } from 'formik';
import { ArrowLeftOutlined, RedoOutlined } from '@ant-design/icons';

import Timer from '../../components/timer';
import BoxAlert from '../../components/boxAlert';
import Inputfield from '../../components/inputField';

import { useAuth } from '../../hooks/auth';
import useCountDown from '../../hooks/useCountDown';
import { useNavigationInfos } from '../../hooks/navigationInfos';

import { emailMask, phoneMask } from '../../constants/masks';

import { validateConfirmationCode } from '../../utils/validation';

import SessionService from '../../services/SessionService';

import {
  ButtonStyled,
  Content,
  FormContent,
  Description,
  FormStyled,
  HeadingStyled,
  HeaderRegister,
  GoBackButton,
  ProgressStyled,
  FormWrapper,
  ConfirmContainer,
  ConfirmationWrapper,
  TimerWrapper,
  TimerText,
  TimerIcon,
  TimerShift,
  Resendwrapper,
  ResendText,
  ResentButton,
  ContainerStyled,
} from './styles';
import Layout from '../../components/layout';

const FieldNames = {
  VERIFICATION_CODE: 'verificationCode',
};

const ConfirmToken = () => {
  const [resetTokenCount, setResetTokenCount] = useState(0);
  const [hasConfirm, setHasConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [percent, setPercent] = useState(0);

  const { time, minutes, seconds, resetCountdow } = useCountDown();
  const { contact, resetToken, setResetToken } = useNavigationInfos();
  const { logIn, logUser } = useAuth();

  const navigate = useNavigate();

  const configApi = {
    onUploadProgress: function(progressEvent: any) {
      setPercent(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
    }
  };

  const handleSubmit = async (values: { [x: string]: string }, setErrors: (errors: FormikErrors<{[x: string]: string;}>) => void) => {
    setHasConfirm(true);
    setShowError(false);

    const sessionService = new SessionService();

    try {
      const response = await sessionService.ValidationToken({token: values[FieldNames.VERIFICATION_CODE]}, configApi);

      logIn(response.data.token);
      logUser({id: response.data.id, name: response.data.name, customerId: response.data.customerId, hasTrackingMap: response.data.hasTrackingMap});

      navigate('/');

    } catch (err) {
      setHasConfirm(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) {
          setShowError(true);
          setErrors({ [FieldNames.VERIFICATION_CODE]: 'Código incorreto. Verifique o código recebido.' });
        } else {
          setShowError(true);
        }
      } else {
        setShowError(true);
      }
    }
  };

  const handleResetToken = async () => {
    const sessionService = new SessionService();

    try {
      if (resetTokenCount >= 3 || !resetToken) {
        navigate(-1);
      } else {
        const response = await sessionService.ResetValidationToken({resetToken});

        setResetToken(response.data.resetToken);
        setResetTokenCount(resetTokenCount + 1);
        resetCountdow();
      }
    } catch(error) {
      navigate(-1);
    }
  }

  const renderConfirmation = () => (
    <ConfirmContainer>
      <Content>
        <HeaderRegister>
          <HeadingStyled type="h2">Autenticação concluída!</HeadingStyled>
          <Description>
            Aguarde enquanto carregamos as informações...
          </Description>
        </HeaderRegister>

        <ProgressStyled percent={percent} status="active" showInfo={false} />
      </Content>
    </ConfirmContainer>
  );

  return (
    <Layout mode={"external"}>
      <ConfirmationWrapper>
        <ContainerStyled>
          {!hasConfirm ? (
            <Content>
              <FormWrapper>
                <GoBackButton
                  mode="action"
                  leftElement={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}
                >
                  voltar
                </GoBackButton>

                <FormContent>
                  <HeaderRegister>
                    <HeadingStyled type="h2">Código enviado</HeadingStyled>
                    <Description>
                      {contact ? 
                        `Enviamos um código de verificação para seu e-mail{' '}
                        ${contact.email && emailMask(contact.email)} e seu celular ${contact.phone && phoneMask(contact.phone)} cadastrados.`
                      : 
                        `Enviamos um código de verificação para seu e-mail e celular cadastrados.`
                      }
                    </Description>
                    {showError ? (
                        <BoxAlert label="Código incorreto. Verifique o código recebido." />
                      ) : 
                      null
                    }
                    <TimerWrapper>
                      <TimerText>O código expira em</TimerText>
                      {time === 0 ? (
                        <ResentButton
                          mode="action"
                          leftElement={<RedoOutlined />}
                          onClick={handleResetToken}
                        >
                          Reenviar código
                        </ResentButton>
                      ) : (
                        <TimerShift>
                          <TimerIcon />
                          <Timer minutes={minutes} seconds={seconds} />
                        </TimerShift>
                      )}
                    </TimerWrapper>
                  </HeaderRegister>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                  >
                    {formik => {
                      const { isValid, dirty } = formik;

                      return (
                        <FormStyled>
                          <Inputfield
                            name={FieldNames.VERIFICATION_CODE}
                            label="Código de verificação*"
                            type="text"
                            maxLength={6}
                            placeholder="Digite o código"
                            tooltipLabel="Insira o código de 6 dígitos que foi enviado para seu celular e seu e-mail."
                            disabled={time === 0 && true}
                          />

                          <ButtonStyled
                            mode="default"
                            htmlType="submit"
                            disabled={!(dirty && isValid)}
                          >
                            Entrar
                          </ButtonStyled>
                        </FormStyled>
                      );
                    }}
                  </Formik>
                  <Resendwrapper>
                    <ResendText>Não recebeu?</ResendText>
                    <ResentButton
                      mode="action"
                      onClick={handleResetToken}
                    >
                      Reenviar código
                    </ResentButton>
                  </Resendwrapper>
                </FormContent>
              </FormWrapper>
            </Content>
          ) : (
            renderConfirmation()
          )}
        </ContainerStyled>
      </ConfirmationWrapper>
    </Layout>
  );
};

export default ConfirmToken;

const initialValues = {
  [FieldNames.VERIFICATION_CODE]: '',
};

const validationSchema = Yup.object().shape({
  [FieldNames.VERIFICATION_CODE]: Yup.string()
    .required('Campo obrigatório')
    .test('validate-code', 'Insira um código válido', validateConfirmationCode),
});
