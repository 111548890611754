function Prepared({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2119 16.904C14.0881 16.8566 13.9507 16.9223 13.9049 17.0508C13.8592 17.1792 13.9226 17.3218 14.0464 17.3692L16.0109 18.1211C16.0382 18.1315 16.0661 18.1365 16.0936 18.1365C16.1909 18.1365 16.2822 18.0745 16.3179 17.9743C16.3636 17.8458 16.3002 17.7033 16.1764 17.6558L14.2119 16.904Z" fill="#757575"/>
      <path d="M14.2119 18.0565C14.0881 18.0091 13.9507 18.0748 13.9049 18.2032C13.8592 18.3317 13.9226 18.4743 14.0464 18.5217L16.0109 19.2736C16.0382 19.284 16.0661 19.2889 16.0936 19.2889C16.1909 19.2889 16.2822 19.2269 16.3179 19.1268C16.3636 18.9983 16.3002 18.8557 16.1764 18.8083L14.2119 18.0565Z" fill="#757575"/>
      <path d="M13.9049 19.3557C13.9507 19.2272 14.0881 19.1616 14.2119 19.2089L16.1764 19.9608C16.3002 20.0082 16.3636 20.1508 16.3179 20.2793C16.2822 20.3794 16.1909 20.4414 16.0936 20.4414C16.0661 20.4414 16.0382 20.4365 16.0109 20.426L14.0464 19.6742C13.9226 19.6268 13.8592 19.4842 13.9049 19.3557Z" fill="#757575"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1369 10.0263L29.738 13.701C29.8945 13.7609 29.9985 13.9156 29.9985 14.0887V25.9117C29.9985 26.0848 29.8945 26.2395 29.738 26.2994L20.1369 29.9742C20.0927 29.9911 20.0459 29.9997 19.9989 29.9997C19.9525 29.9997 19.9058 29.9913 19.8611 29.9742L10.26 26.2994C10.1035 26.2395 9.99951 26.0848 9.99951 25.9117V14.0887C9.99951 13.9536 10.0632 13.8269 10.1701 13.7496C10.1983 13.7293 10.2285 13.7134 10.2599 13.7013L19.8611 10.0263C19.9501 9.99222 20.0479 9.99222 20.1369 10.0263ZM10.7963 25.6237L19.6005 28.9935V18.0514L17.6883 17.3195V22.0216C17.6883 22.1568 17.6246 22.2834 17.5177 22.3606C17.4499 22.4096 17.3703 22.4349 17.2899 22.4349C17.2434 22.4349 17.1968 22.4265 17.152 22.4093L12.8494 20.7625C12.6929 20.7026 12.589 20.5479 12.589 20.3748V15.3678L10.7963 14.6816V25.6237ZM16.8916 21.4286V17.0146L13.3858 15.6727V20.0868L16.8916 21.4286ZM17.29 13.8702L14.1382 15.0765L17.29 16.2828L20.4417 15.0765L17.29 13.8702ZM18.4365 16.7249L19.999 17.3229L28.4492 14.0887L19.999 10.8545L11.5488 14.0887L12.9831 14.6377L17.152 13.042C17.2411 13.0079 17.3389 13.0079 17.4279 13.042L21.7305 14.6887C21.887 14.7487 21.9909 14.9034 21.9909 15.0764C21.9909 15.2495 21.887 15.4042 21.7305 15.4641L18.4365 16.7249ZM20.3974 28.9935L29.2017 25.6237V14.6816L20.3974 18.0514V28.9935Z" fill="#757575"/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#9E9E9E"/>
    </svg>
  );
}

export default Prepared;
