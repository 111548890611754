import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  ContainerStyled,
  ButtonContainer,
  ButtonOutlined,
} from '../styles';

const ExpiredQueryInvoicing = () => {
  const navigate = useNavigate();

  return (
    <Layout mode={"external"}>
      <ContainerStyled>
        <Content>
          <HeadingStyled type="h1">Link expirado</HeadingStyled>

          <Description>
            O link que você está tentando acessar expirou. Clique no botão abaixo
            para receber um novo link ou entre com suas informações para ver suas
            notas.
          </Description>

          <ButtonContainer>
            <ButtonStyled decrease mode="default" onClick={() => navigate('/')}>
              Receber outro link
            </ButtonStyled>
            <ButtonOutlined mode="outline" onClick={() => navigate('/')}>
              Ir para a página inicial
            </ButtonOutlined>
          </ButtonContainer>
        </Content>
      </ContainerStyled>
    </Layout>
  );
};

export default ExpiredQueryInvoicing;
