import styled, { css, keyframes } from 'styled-components';

import Heading from '../../components/heading';
import Text from '../../components/text';
import Button from '../../components/button';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  
  to {
    opacity: 0;
    transform: translateX(100px);
  }
`;

export const WelcomeContainer = styled.div`
  height: calc(100vh - 8.99rem);
  display: flex;
  align-items: center;
`;

export const Content = styled.div<{ animated: boolean }>`
  width: 48vw;
  max-width: 504px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  animation: ${props =>
    props.animated === false
      ? css`
          ${fadeIn} 1s both
        `
      : css`
          ${fadeOut} 1s both
        `};

  ${({ theme }) => theme.media.md`
    width: 100%;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 206px;
   `}
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonStyled = styled(Button)`
  width: 154px;
  height: 48px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;
