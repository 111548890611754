import React, { Fragment }from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { ExternalPaths, InternalPaths } from '../constants/routes';
import { APIError, ExpiredLink, ExpiredQueryInvoicing, NotFound, TimeOut } from '../flows/erros';

import ConfirmToken from '../flows/confirmToken';
import ConsultByLink from '../flows/consultByLink';
import NFDetail from '../flows/dashboard/pages/nfDetail';
import NFList from '../flows/dashboard/pages/nfList';
import Register from '../flows/firstAccess/pages/register';
import RegisterForm from '../flows/firstAccess/pages/registerForm';
import Consult from '../flows/manualConsult/pages/consult';
import Home from '../flows/manualConsult/pages/home';

import PrivateRoute from './RouteComponent';
import { useAuth } from '../hooks/auth';



const AppRoutes: React.FC = () => {
  const { user } = useAuth();

  const tagManagerArgs = {
    gtmId: 'GTM-PDCCHSQ',
    dataLayer: {
      userId: (user|| {}).id,
      custumerId: (user|| {}).customerId,
      name: (user|| {}).name,
    },
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <BrowserRouter>
      <Routes>
        <Fragment>
          
          <Route path={ExternalPaths.CONSULT} element={<Consult />} />
          <Route path={ExternalPaths.INDEX} element={<Home />} />
          <Route path={ExternalPaths.LINK} element={<ConsultByLink />} />
          <Route path={ExternalPaths.CONFIRM_TOKEN} element={<ConfirmToken />} />
          <Route path={ExternalPaths.REGISTER} element={<Register />} />
          <Route path={ExternalPaths.REGISTER_FORM} element={<RegisterForm />} />
          <Route path={ExternalPaths.URL_SHORTENER} element={<ConsultByLink />} />

          <Route path={ExternalPaths.TIMEOUT} element={<TimeOut />} />
          <Route path={ExternalPaths.EXPIRED_QUERY} element={<ExpiredQueryInvoicing />} />
          <Route path={ExternalPaths.EXPIRED_LINK} element={<ExpiredLink />} />
          <Route path={ExternalPaths.API_ERROR} element={<APIError />} />

          <Route path={InternalPaths.INDEX} element={<PrivateRoute />}>
            <Route path={InternalPaths.INDEX} element={<NFList />} />
          </Route>
          <Route path={InternalPaths.DETAIL} element={<PrivateRoute />}>
            <Route path={InternalPaths.DETAIL} element={<NFDetail />} />
          </Route>

          {/* 404 */}
          <Route path='*' element={<NotFound />} />
        </Fragment>
      </Routes>
      </BrowserRouter>
  );
}

export default AppRoutes;
