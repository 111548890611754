import { FC } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { 
  Counter,
  NoInvoice, 
  ArrowLeft, 
  ArrowRight, 
  ArrowWrapper,
  NoPhotosIcon, 
  StyledCarousel,
} from './styles';

interface CarouselProps {
  images: Array<any>;
  currentPage: number;
  setOverlay: Function;
  setCurrentPage: Function;
  isCarouselOverlay?: boolean;
}

const Carousel: FC<CarouselProps> = ({
  images,
  setOverlay,
  currentPage,
  setCurrentPage,
  isCarouselOverlay = false,
 }) => {

  const renderArrow = (side: string, clickHandler: () => void) => {
    return (
      <ArrowWrapper>
        {side === 'left' ? (
          <ArrowLeft onClick={clickHandler}>
            <LeftOutlined />
          </ArrowLeft>
        ) : (
          <ArrowRight onClick={clickHandler}>
            <RightOutlined />
          </ArrowRight>
        )}    
      </ArrowWrapper>
    )
  }

  return (
    <>
      {images.length > 0 ? (
        <>
          <StyledCarousel
            showThumbs={false} 
            showArrows={false} 
            showIndicators={false} 
            onChange={(e) => setCurrentPage(e)}
            onClickItem={() => setOverlay(true)}
            isCarouselOverlay={isCarouselOverlay}
            renderArrowPrev={(clickHandler) => renderArrow('left', clickHandler)}
            renderArrowNext={(clickHandler) => renderArrow('right', clickHandler)}
          >
            {images.map((image, key) => (
              <img key={key} src={image.cte.cteViewUrl || image.cte.cteDownloadUrl} alt="Encomenda" />
            ))}
          </StyledCarousel>
          
          <Counter>{currentPage + 1}/{images.length}</Counter>
        </>
      ) : (
        <NoInvoice>
          <NoPhotosIcon />
          Sem registros da entrega
        </NoInvoice>
      )}
    </>
  );
}

export default Carousel;
