import styled from 'styled-components';

import theme from '../../../../../../constants/theme';

export const ForecastWrapper = styled.span`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

export const ContentSubtitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${theme.colors.blue.darkness};
`;

export const Forecast = styled.div`
  font-weight: 400;
  font-size: 32px;
  color: ${theme.colors.blue.midle};
`;

export const ContentDescription = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #${theme.colors.grey.darkness_mid};
`;

export const DestinationWrapper = styled.div`
  margin-top: 1rem;
`;

export const RecipientWrapper = styled.div`
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const VolumeWrapper = styled.div`
  margin-left: 1rem;
`;
