import React from 'react';

function ListView({ ...props }) {
  return (
    <svg viewBox="0 0 17 14" {...props}>
      <path d="M0 9h4V5H0v4zm0 5h4v-4H0v4zM0 4h4V0H0v4zm5 5h12V5H5v4zm0 5h12v-4H5v4zM5 0v4h12V0H5z"></path>
    </svg>
  );
}

export default ListView;
