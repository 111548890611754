import styled from 'styled-components';
import Text from '../text';

export const BoxWrapper = styled.div`
  background: ${({ theme }) => theme.colors.yellow.dark_rgba};
  border-radius: 4px;
  padding: 8px 16px;

`;

export const MessageText = styled(Text)`
  color: ${({ theme }) => theme.colors.yellow.mid};
  font-size: 12px;
`;
