import styled from 'styled-components';
import { MailFilled, PhoneFilled } from '@ant-design/icons';

import Container from '../container';
import Text from '../text';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background: none;

  span {
    color: ${({ theme }) => theme.colors.grey.light};
    font-size: 10px;
  }

  ${({ theme }) => theme.media.md`
    height: 48px;
  `}
`;

export const InternalWrapper = styled.div`
  background: ${({ theme }) => theme.colors.blue.dark};
`;

export const ContainerStyled = styled(Container)``;

export const Content = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.media.md`
    height: 143px;
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const FirstContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    padding-top: 16px;
  `}
`;

export const FooterTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 10px;
`;

export const FooterDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
  font-size: 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const IconContent = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.colors.blue.mid};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MailStyled = styled(MailFilled)`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export const PhoneStyled = styled(PhoneFilled)`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export const InfoText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 12px;
`;

export const SecondContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-end;
  align-items: self-end;

  padding-bottom: 32px;

  span {
    color: ${({ theme }) => theme.colors.grey.light};
    font-size: 10px;
  }

  ${({ theme }) => theme.media.md`
    align-items: flex-start;
    padding-bottom: 16px;
  `}
`;
