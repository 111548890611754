import { FC } from 'react';
import styled from 'styled-components';
import Text from '../text';

const TimerText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 12px;
  `}
`;

interface TimeProps {
  minutes: number;
  seconds: number;
}

const Timer: FC<TimeProps> = ({ minutes, seconds, ...props }) => {
  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('');

  return (
    <TimerText {...props}>
      {minuteLeft}
      {minuteRight}:{secondsLeft}
      {secondsRight}
    </TimerText>
  );
};

export default Timer;
