import { FC, ReactNode } from 'react';

import { Heading1, Heading2 } from './styles';

interface HeadingProps {
  children: ReactNode;
  type: string;
}

const Heading: FC<HeadingProps> = ({ type, children, ...rest }) => {
  return (
    <>
      {type === 'h1' && (
        <Heading1 as={type} {...rest}>
          {children}
        </Heading1>
      )}

      {type === 'h2' && (
        <Heading2 as={type} {...rest}>
          {children}
        </Heading2>
      )}
    </>
  );
};

export default Heading;
