const ErrosPaths = {
  NOT_FOUND: '*',
  TIMEOUT: '/timeOut',
  EXPIRED_QUERY: '/expired-query',
  EXPIRED_LINK: '/expired-link',
  API_ERROR: '/api-error',
};

export const ExternalPaths = {
  INDEX: '/home',
  CONSULT: '/consult',
  LINK: '/link',
  CONFIRM_TOKEN: '/confirm-token',
  REGISTER: '/register',
  REGISTER_FORM: '/form',
  URL_SHORTENER: '/l/:token',
  ...ErrosPaths,
};

export const InternalPaths = {
  INDEX: '/',
  DETAIL: '/detail/:id',
};
