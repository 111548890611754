import styled, { css } from 'styled-components';

import Container from '../../components/container';

import Button from '../../components/button';
import Heading from '../../components/heading';
import Text from '../../components/text';

const commonButton = css`
  height: 48px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const ContainerStyled = styled(Container)``;

export const Content = styled.div`
  width: 48vw;
  max-width: 590px;
  height: calc(100vh - 10.3em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  ${({ theme }) => theme.media.md`
    width: 100%;
    max-width: unset;
  `}
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const ButtonStyled = styled(Button)<{ decrease?: boolean }>`
  width: ${({ decrease }) => (decrease ? '190px' : '227px')};

  ${commonButton};
`;

export const ButtonOutlined = styled(Button)`
  width: 227px;

  ${commonButton};
`;
