import {
  css,
  CSSObject,
  SimpleInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components';

type IBreakPoints<T> = { [key: string]: T };

export const Breakpoints: IBreakPoints<number> = {
  xs: 360,
  sm: 425,
  md: 768,
  lg: 1024,
  x: 1366,
  xl: 1440,
  xxl: 2560,
};

const theme = {
  setContainer: () => `
    width: 100%;
    margin: 0 auto;
    padding: 0 210px;

    @media screen and (max-width: 1440px) {
      padding: 0 82px;
    }

    @media screen and (max-width: 1360px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 1024px) {
      padding-left: 32px;
      padding-right: 60px;
    }

    @media screen and (max-width: 960px) {
      padding-right: 32px;
    }
  `,

  colors: {
    white: '#fff',
    blue: {
      darkness_rgba: 'rgba(22, 31, 59, 0.4)',
      dark_rgba: 'rgba(16, 119, 185, 0.08)',
      dark_gray_rgba: 'rgba(163, 214, 255, 0.25)',
      dark_gray_mid_rgba: 'rgba(163, 214, 255, 0.45)',
      dark_gray_light_rgba: 'rgba(163, 214, 255, 0.7)',
      dark: '#161f3b',
      darkness: '#263465',
      midless: '#697BA9',
      midle: '#1077b9',
      mid: '#0da6f2',
      light: '#a3d6ff',
    },
    grey: {
      lightness: 'rgba(0, 0, 0, 0.2)',
      lighting_rgba: 'rgba(0, 0, 0, 0.1)',
      light_rgba: 'rgba(255, 255, 255, 0.75)',
      light: '#F5F5F5',
      mid: '#eeeeee',
      midle: '#616161',
      darkness: '#808080',
      darkness_mid: '#757575',
      darkness_rgba: 'rgba(255, 255, 255, 0.1)',
    },
    yellow: {
      mid: '#F5D024',
      light_rgba: 'rgba(252, 239, 182, 0.7)',
      dark_rgba: 'rgba(252, 239, 182, 0.25)',
    },
    green: {
      mid: '#3B873E',
    },
    orange: {
      mid: '#FF9800',
    },
    red: {
      mid: '#F44336',
    },
  },

  media: Object.keys(Breakpoints).reduce(
    (
      acc: {
        [key: string]: (
          first: TemplateStringsArray | CSSObject,
          ...interpolations: SimpleInterpolation[]
        ) => FlattenSimpleInterpolation;
      },
      label,
    ) => {
      acc[label] = (...args) => css`
        @media (max-width: ${Breakpoints[label]}px) {
          ${css(...args)};
        }
      `;
      return acc;
    },
    {},
  ),
};

export default theme;
