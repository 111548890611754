function Delivered({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6669 15.3508C12.7059 15.2412 12.8232 15.1851 12.929 15.2255L14.6057 15.8673C14.7114 15.9078 14.7655 16.0294 14.7265 16.1391C14.696 16.2246 14.618 16.2775 14.535 16.2775C14.5116 16.2775 14.4877 16.2733 14.4644 16.2644L12.7877 15.6226C12.682 15.5822 12.6279 15.4605 12.6669 15.3508Z" fill="#757575"/>
      <path d="M12.6669 16.3345C12.7059 16.2248 12.8232 16.1688 12.929 16.2092L14.6057 16.851C14.7114 16.8914 14.7655 17.0131 14.7265 17.1228C14.696 17.2083 14.618 17.2612 14.535 17.2612C14.5116 17.2612 14.4877 17.257 14.4644 17.2481L12.7877 16.6063C12.682 16.5658 12.6279 16.4442 12.6669 16.3345Z" fill="#757575"/>
      <path d="M12.929 17.1929C12.8232 17.1525 12.7059 17.2085 12.6669 17.3182C12.6279 17.4278 12.682 17.5495 12.7877 17.59L14.4644 18.2317C14.4877 18.2406 14.5116 18.2449 14.535 18.2449C14.618 18.2449 14.696 18.1919 14.7265 18.1065C14.7655 17.9968 14.7114 17.8751 14.6057 17.8347L12.929 17.1929Z" fill="#757575"/>
      <path d="M27.5271 22.4941C27.7241 22.3246 27.7464 22.0274 27.5769 21.8304C27.4073 21.6334 27.1102 21.6111 26.9132 21.7806L22.5775 25.5112L21.1717 23.7507C21.0094 23.5477 20.7132 23.5147 20.5102 23.6771C20.3072 23.8394 20.2742 24.1356 20.4366 24.3386L21.9947 26.2896C22.2444 26.6019 22.7034 26.6447 23.0065 26.3839L27.5271 22.4941Z" fill="#757575"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.181 12.4917L17.9861 9.35516C17.9102 9.32611 17.8267 9.32611 17.7507 9.35516L9.55577 12.4919C9.52893 12.5022 9.50313 12.5159 9.47912 12.5332C9.38787 12.5992 9.3335 12.7073 9.3335 12.8226V22.914C9.3335 23.0617 9.42221 23.1938 9.5558 23.2449L17.7507 26.3814C17.7585 26.3844 17.7664 26.3871 17.7743 26.3895C18.7349 28.8912 21.1601 30.6669 23.9999 30.6669C27.6817 30.6669 30.6664 27.6822 30.6664 24.0005C30.6664 21.166 28.8974 18.7448 26.4033 17.7804V12.8226C26.4033 12.6748 26.3146 12.5428 26.181 12.4917ZM17.3335 24.0005C17.3335 23.4466 17.401 22.9086 17.5283 22.3941V16.2049L15.8961 15.5802V19.5936C15.8961 19.709 15.8418 19.8171 15.7506 19.883C15.6927 19.9248 15.6247 19.9464 15.5561 19.9464C15.5164 19.9464 15.4766 19.9392 15.4384 19.9246L11.766 18.519C11.6324 18.4678 11.5437 18.3357 11.5437 18.188V13.9144L10.0136 13.3287V22.6682L17.5117 25.538C17.3952 25.0444 17.3335 24.5297 17.3335 24.0005ZM23.9999 17.334C21.5203 17.334 19.3569 18.6878 18.2085 20.6966V16.2049L25.7233 13.3287V17.5589C25.1735 17.4122 24.5959 17.334 23.9999 17.334ZM15.2161 15.3199V19.0875L12.2238 17.9422V14.1746L15.2161 15.3199ZM12.8661 13.6657L15.5561 12.6361L18.2462 13.6657L15.5561 14.6953L12.8661 13.6657ZM17.8684 15.5831L16.5348 15.0727L19.3463 13.9966C19.4799 13.9454 19.5686 13.8134 19.5686 13.6657C19.5686 13.5179 19.4799 13.3859 19.3463 13.3348L15.6739 11.9292C15.5979 11.9001 15.5144 11.9001 15.4384 11.9292L11.8801 13.2912L10.6558 12.8226L17.8684 10.0621L25.081 12.8226L17.8684 15.5831ZM23.9999 29.8002C27.2031 29.8002 29.7997 27.2036 29.7997 24.0005C29.7997 20.7973 27.2031 18.2007 23.9999 18.2007C20.7968 18.2007 18.2002 20.7973 18.2002 24.0005C18.2002 27.2036 20.7968 29.8002 23.9999 29.8002Z" fill="#757575"/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#9E9E9E"/>
    </svg>

  );
}

export default Delivered;
