function PreparedDone({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#A3D6FF"/>
      <path d="M14.5985 17.11C14.4829 17.0658 14.3547 17.1271 14.312 17.247C14.2693 17.3669 14.3285 17.5 14.4441 17.5442L16.2777 18.246C16.3031 18.2557 16.3292 18.2604 16.3549 18.2604C16.4456 18.2604 16.5309 18.2025 16.5642 18.109C16.6069 17.9891 16.5477 17.856 16.4321 17.8118L14.5985 17.11Z" fill="#161F3B"/>
      <path d="M14.5985 18.1857C14.4829 18.1415 14.3547 18.2028 14.312 18.3227C14.2693 18.4426 14.3285 18.5757 14.4441 18.6199L16.2777 19.3217C16.3031 19.3314 16.3292 19.3361 16.3549 19.3361C16.4456 19.3361 16.5309 19.2782 16.5642 19.1847C16.6069 19.0648 16.5477 18.9317 16.4321 18.8875L14.5985 18.1857Z" fill="#161F3B"/>
      <path d="M14.312 19.3984C14.3547 19.2785 14.4829 19.2172 14.5985 19.2614L16.4321 19.9632C16.5477 20.0074 16.6069 20.1405 16.5642 20.2604C16.5309 20.3539 16.4456 20.4118 16.3549 20.4118C16.3292 20.4118 16.3031 20.4072 16.2777 20.3974L14.4441 19.6956C14.3285 19.6514 14.2693 19.5183 14.312 19.3984Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1288 10.6905L29.0903 14.1204C29.2364 14.1763 29.3334 14.3207 29.3334 14.4822V25.5177C29.3334 25.6792 29.2364 25.8236 29.0903 25.8796L20.1288 29.3095C20.0875 29.3253 20.0438 29.3333 20 29.3333C19.9567 29.3333 19.9131 29.3255 19.8713 29.3095L10.9099 25.8796C10.7638 25.8236 10.6667 25.6792 10.6667 25.5177V14.4822C10.6667 14.3561 10.7262 14.2379 10.826 14.1658C10.8522 14.1468 10.8805 14.1319 10.9098 14.1207L19.8714 10.6905C19.9544 10.6587 20.0457 10.6587 20.1288 10.6905ZM11.4104 25.2489L19.6282 28.3942V18.181L17.8433 17.4979V21.8867C17.8433 22.0128 17.7839 22.131 17.6841 22.2031C17.6208 22.2489 17.5465 22.2724 17.4714 22.2724C17.4281 22.2724 17.3845 22.2646 17.3427 22.2486L13.3268 20.7115C13.1807 20.6556 13.0837 20.5111 13.0837 20.3496V15.6762L11.4104 15.0357V25.2489ZM17.0997 21.3332V17.2132L13.8274 15.9608V20.0807L17.0997 21.3332ZM17.4715 14.2783L14.5298 15.4042L17.4715 16.5302L20.4133 15.4042L17.4715 14.2783ZM18.5417 16.9429L20.0001 17.501L27.8874 14.4823L20.0001 11.4635L12.1128 14.4822L13.4515 14.9947L17.3428 13.5053C17.4259 13.4735 17.5172 13.4735 17.6002 13.5053L21.6162 15.0423C21.7623 15.0983 21.8593 15.2426 21.8593 15.4042C21.8593 15.5658 21.7623 15.7101 21.6162 15.7661L18.5417 16.9429ZM20.372 28.3941L28.5897 25.2489V15.0357L20.372 18.181V28.3941Z" fill="#161F3B"/>
    </svg>
  );
}

export default PreparedDone;
