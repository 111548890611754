function DeliveredDone({ ...props }) {
  return (
    <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.399902 20C0.399902 8.95431 9.35421 0 20.3999 0C31.4456 0 40.3999 8.95431 40.3999 20C40.3999 31.0457 31.4456 40 20.3999 40C9.35421 40 0.399902 31.0457 0.399902 20Z" fill="#F88078"/>
      <path d="M16.5301 16.1303C16.7036 15.9567 16.9851 15.9567 17.1586 16.1303L20.3999 19.3716L23.6412 16.1303C23.8148 15.9567 24.0962 15.9567 24.2697 16.1303C24.4433 16.3039 24.4433 16.5853 24.2697 16.7588L21.0284 20.0001L24.2697 23.2414C24.4433 23.415 24.4433 23.6964 24.2697 23.8699C24.0962 24.0435 23.8148 24.0435 23.6412 23.8699L20.3999 20.6287L17.1586 23.8699C16.9851 24.0435 16.7036 24.0435 16.5301 23.8699C16.3565 23.6964 16.3565 23.415 16.5301 23.2414L19.7714 20.0001L16.5301 16.7588C16.3565 16.5853 16.3565 16.3039 16.5301 16.1303Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4 10.5801C15.1975 10.5801 10.98 14.7976 10.98 20.0001C10.98 25.2026 15.1975 29.4201 20.4 29.4201C25.6025 29.4201 29.82 25.2026 29.82 20.0001C29.82 14.7976 25.6025 10.5801 20.4 10.5801ZM11.82 20.0001C11.82 15.2615 15.6614 11.4201 20.4 11.4201C25.1386 11.4201 28.98 15.2615 28.98 20.0001C28.98 24.7387 25.1386 28.5801 20.4 28.5801C15.6614 28.5801 11.82 24.7387 11.82 20.0001Z" fill="#161F3B"/>
    </svg>
  );
}

export default DeliveredDone;
