import React, { createContext, useCallback, useState, useContext } from 'react';
import LocalStorageKeys from '../constants/LocalStorage';

import SessionService from '../services/SessionService';

interface AuthState {
  id: number;
  name: string;
  customerId: number;
  hasTrackingMap: boolean;
}

interface AuthContextData {
  user: AuthState | null;
  token: string | null;
  logIn(token: string): void;
  logUser(user: AuthState): void;
  logOut(): void;
  validationToken(): Promise<boolean>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<AuthState | null>(() => {
    const user = localStorage.getItem(LocalStorageKeys.USER_DATA);

    if (user) {
        const { name, id, customerId, hasTrackingMap } = JSON.parse(user) as AuthState;
      return { name, id, customerId, hasTrackingMap };
    }

    return null;
  });

  const [token, setToken] = useState<string | null>(() => {
    const sessionToken = localStorage.getItem(LocalStorageKeys.SESSION_TOKEN);

    return sessionToken;
  });

  const logIn = useCallback((token: string) => {
    localStorage.setItem(LocalStorageKeys.SESSION_TOKEN, token);

    setToken(token);
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem(LocalStorageKeys.SESSION_TOKEN);
    localStorage.removeItem(LocalStorageKeys.USER_DATA);
    setUserData(null);
  }, []);

  const logUser = useCallback((user: AuthState ) => {
    localStorage.setItem(LocalStorageKeys.USER_DATA, JSON.stringify(user));

    setUserData(user);
  }, []);

  const validationToken = async () => {
    const sessionService = new SessionService();

    try {
      await sessionService.ValidationTokenAuth();

      return true;
    } catch(error) {
      logOut();

      return false;
    }
  }

  return (
    <AuthContext.Provider value={{ user: userData, token, logIn, logOut, logUser, validationToken }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  
  return context;
}

export { AuthProvider, useAuth };
