function InTransitDone({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#A3D6FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 11.6278C10.6667 11.0211 11.0209 10.6666 11.6269 10.6666H17.6623C18.2684 10.6666 18.6225 11.0211 18.6225 11.6278V17.6692C18.6225 18.2759 18.2684 18.6304 17.6623 18.6304H11.6269C11.0209 18.6304 10.6667 18.2759 10.6667 17.6692V11.6278ZM11.6269 11.4905H12.93V13.0695C12.93 13.3728 13.1757 13.6187 13.4787 13.6187H15.8106C16.1136 13.6187 16.3592 13.3728 16.3592 13.0695V11.4905H17.6623C17.7381 11.4905 17.7995 11.5519 17.7995 11.6278V17.6692C17.7995 17.7451 17.7381 17.8065 17.6623 17.8065H11.6269C11.5512 17.8065 11.4898 17.7451 11.4898 17.6692V11.6278C11.4898 11.5519 11.5512 11.4905 11.6269 11.4905ZM13.6847 12.8635V11.4905H15.6051V12.8635H13.6847Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3777 22.3307C21.3777 21.724 21.7318 21.3695 22.3378 21.3695H28.3732C28.9793 21.3695 29.3334 21.724 29.3334 22.3307V28.3721C29.3334 28.9788 28.9793 29.3333 28.3732 29.3333H22.3378C21.7318 29.3333 21.3777 28.9788 21.3777 28.3721V22.3307ZM22.3378 22.1934H23.6409V23.7724C23.6409 24.0757 23.8866 24.3216 24.1896 24.3216H26.5215C26.8245 24.3216 27.0701 24.0757 27.0701 23.7724V22.1934H28.3732C28.449 22.1934 28.5104 22.2548 28.5104 22.3307V28.3721C28.5104 28.448 28.449 28.5095 28.3732 28.5095H22.3378C22.2621 28.5095 22.2007 28.448 22.2007 28.3721V22.3307C22.2007 22.2548 22.2621 22.1934 22.3378 22.1934ZM24.3956 23.5664V22.1934H26.316V23.5664H24.3956Z" fill="#161F3B"/>
      <path d="M26.3867 11.3873C26.2176 11.2157 25.9409 11.2157 25.7718 11.3873C25.6059 11.5557 25.6059 11.8262 25.7718 11.9946L27.9575 14.2125L21.8123 14.2125C21.5723 14.2125 21.3777 14.4073 21.3777 14.6476C21.3777 14.8879 21.5723 15.0827 21.8123 15.0827L27.958 15.0827L25.7718 17.3012C25.6059 17.4696 25.6059 17.7401 25.7718 17.9084C25.9409 18.08 26.2176 18.08 26.3867 17.9084L29.1449 15.1096C29.3962 14.8546 29.3962 14.4412 29.1449 14.1862L26.3867 11.3873Z" fill="#161F3B"/>
      <path d="M14.2296 28.6116C14.0604 28.7833 13.7838 28.7833 13.6147 28.6116L10.8565 25.8129C10.6053 25.5579 10.6053 25.1445 10.8565 24.8895L13.6147 22.0907C13.7838 21.9191 14.0604 21.9191 14.2296 22.0907C14.3955 22.2591 14.3955 22.5296 14.2296 22.6979L12.0434 24.9164L18.1889 24.9164C18.429 24.9164 18.6236 25.1112 18.6236 25.3515C18.6236 25.5918 18.429 25.7866 18.1889 25.7866L12.0439 25.7866L14.2296 28.0044C14.3955 28.1728 14.3955 28.4433 14.2296 28.6116Z" fill="#161F3B"/>
    </svg>
  );
}

export default InTransitDone;
