import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';

import OrderService from '../services/OrderService';

import { useAuth } from '../hooks/auth';
import { ExternalPaths } from '../constants/routes';

export interface IInvoice {
  DataHora_TMS: string;
  DtPrevisao_TMS: string;
  Emitente_TMS: string;
  NumPedido_TMS: string;
  isNotas: string;
  status_codigo: number;
}

interface InvoiceContextProviderProps {
  children: ReactNode;
}

interface InvoiceContextData {
  allOrders: IInvoice[];
  searchValue: IInvoice[];
  countOrders: number;
  loading: boolean;
  showResult: boolean;
  getAllOrders: () => void;
  getOnlyOrder: (value: string) => void;
  setShowResult: (arg0: boolean) => void;
  getOrderByFilter: (value: string) => void;
  setSearchValue: (arg0: IInvoice[]) => void;
}

export interface IGetParams {
  skip: number;
  take: number;
  filter?: string;
}

const InvoiceContext = createContext<InvoiceContextData>(
  {} as InvoiceContextData,
);

export function InvoiceProvider({
  children,
}: InvoiceContextProviderProps): JSX.Element {
  const [allOrders, setAllOrders] = useState<IInvoice[]>([]);
  const [countOrders, setCountOrders] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<string>();
  const [searchValue, setSearchValue] = useState<IInvoice[]>([]);
  const [showResult, setShowResult] = useState(false);
  const [skipPeirPage, setSkipPeirPage] = useState(0);
  const [takePeirPage] = useState(12);

  const [loading, setLoading] = useState(true);
  const { logOut } = useAuth();

  const getAllOrders = async () => {
    let getParams: IGetParams = {
      skip: 0,
      take: 12,
    };

    if (loading && skipPeirPage !== 0) {
      return;
    }

    if (skipPeirPage === 0) {
      setLoading(true);
    }
    console.log('params 1', getParams, searchParams);
    if (searchParams) {
      getParams = {
        skip: skipPeirPage,
        take: takePeirPage,
        filter: searchParams,
      }
    } else {
      getParams = {
        skip: skipPeirPage,
        take: takePeirPage,
      }
    }
    console.log('params 2', getParams, searchParams);
    try {
      const requestHttp = new OrderService();
      const response = await requestHttp.getHistory(getParams);

      setAllOrders([...allOrders, ...response.data.list]);
      setCountOrders(response.data.count);
      setSkipPeirPage(skipPeirPage + 12);
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          console.log('invoices 401', err);
          logOut();
          window.location.assign(ExternalPaths.TIMEOUT);
        } else if (err.response?.status === 500) {
          window.location.assign(ExternalPaths.API_ERROR);
        } else {
          window.location.assign(ExternalPaths.API_ERROR);
        }
      } else {
        window.location.assign(ExternalPaths.API_ERROR);
      } 
    }
  };

  const getOnlyOrder = async (value: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const requestHttp = new OrderService();
      const response = await requestHttp.getHistoryByOrder(value);
      console.log(response.data);
      setLoading(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          logOut();
          window.location.assign(ExternalPaths.TIMEOUT);
        } else if (err.response?.status === 500) {
          window.location.assign(ExternalPaths.API_ERROR);
        } else {
          window.location.assign(ExternalPaths.API_ERROR);
        }
      } else {
        window.location.assign(ExternalPaths.API_ERROR);
      } 
    }
  };

  const getOrderByFilter = async (value: string) => {
    setSearchParams(value);
    setSkipPeirPage(0);

    if (loading) {
      return;
    }

    if (loading && skipPeirPage !== 0) {
      return;
    }
      
    setLoading(true);
    console.log(value);
    try {
      const requestHttp = new OrderService();
      const response = await requestHttp.getHistory({
        skip: skipPeirPage,
        take: takePeirPage,
        filter: searchParams,
      });
      console.log(response);

      setAllOrders([...response.data.list]);
      setSearchValue(response.data.list);
      setCountOrders(response.data.count);
      setSkipPeirPage(skipPeirPage + 12);
      setShowResult(true);
      setLoading(false);

      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          logOut();
          window.location.assign(ExternalPaths.TIMEOUT);
        } else if (err.response?.status === 500) {
          //window.location.assign(ExternalPaths.API_ERROR);
        } else {
          window.location.assign(ExternalPaths.API_ERROR);
        }
      } else {
        window.location.assign(ExternalPaths.API_ERROR);
      } 
    }
  };

  return (
    <InvoiceContext.Provider
      value={{
        allOrders,
        searchValue,
        countOrders,
        getAllOrders,
        getOnlyOrder,
        getOrderByFilter,
        setSearchValue,
        loading,
        showResult,
        setShowResult,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
}

export function useInvoice() {
  const context = useContext(InvoiceContext);

  return context;
}
