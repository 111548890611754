import styled from 'styled-components';
import { Input } from 'antd';
import Heading from '../../../../../components/heading';
import Button from '../../../../../components/button';
import { SearchOutlined, UpOutlined } from '@ant-design/icons';
import Text from '../../../../../components/text';

const { Search } = Input;

export const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(163, 214, 255, 0.1),
      rgba(163, 214, 255, 0.1)
    ),
    ${({ theme }) => theme.colors.white};

  padding: 40px 0;
  min-height: calc(100vh - 206px);

  ${({ theme }) => theme.media.md`
    padding: 16px 0 32px;
  `}
`;

export const ContainerStyled = styled.div`
  ${({ theme }) => theme.setContainer()};

  ${({ theme }) => theme.media.md`
      margin: 0;
      padding: 0;
    }
  `}
`;

export const TextStyled = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey.darkness_mid};
`;

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${({ theme }) => theme.media.md`
    div:first-child {
      margin: 0 auto;
      padding: 0 24px;
    }
  `}
`;

export const ResultContainer = styled.div<{ showResult: boolean }>`
  display: ${({ showResult }) => (showResult ? 'block' : 'none')};
  width: 100%;
  height: 64px;
  background: ${({ theme }) => theme.colors.blue.dark_gray_mid_rgba};
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ease 0.2s;

  ${({ theme }) => theme.media.md`
      width: 100%;
      height: 76px;
      border-radius: unset;
  `}
`;

export const SearchContainer = styled.div`
  display: none;

  ${({ theme }) => theme.media.md`
    background:${theme.colors.blue.dark_gray_mid_rgba};;
    width: 100%;
    height: 92px;
    border-radius: 8px;
    padding: 16px 24px;
    border-radius: unset;
    display: flex;
    align-items: center;
    transition: ease 0.2s;
  `}
`;

export const ResultSearchText = styled(Text)`
  color: ${({ theme }) => theme.colors.blue.darkness};
  font-weight: normal;
  font-size: 14px;
`;

export const ButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.colors.blue.midle} !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 89px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.blue.darkness};
`;

export const SearchStyled = styled(Search)<{ isMobile?: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  width: 100%;
  max-width: ${({ isMobile }) => (isMobile ? 'unset' : '328px')};
  height: 44px;

  span {
    height: 44px;

    input {
      height: 44px;
    }
    span {
      button {
        height: 44px;
        display: flex;
        align-items: center;
        span {
          height: auto;
          color: ${({ theme }) => theme.colors.blue.dark};
          height: 20px;
          width: 20px;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  ${({ theme, isMobile }) => theme.media.md`
    display: ${isMobile ? 'block' : 'none'};
  `}
`;

export const ButtonSearch = styled(Button)`
  display: none;

  ${({ theme }) => theme.media.md`
    display: block;
  `}
`;

export const SearchIcon = styled(SearchOutlined)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ArrowUpIcon = styled(UpOutlined)`
  svg {
    width: 20px;
    height: 20px;
  }
`;
