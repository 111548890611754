import { FC, ReactElement } from 'react';

import { format } from 'date-fns';
import { Steps as AntdSteps } from 'antd';
import { STEP_COLUMN } from '../../../../../../constants/stepTracking';

import { 
  Created, 
  OnTheWay, 
  Prepared,
  InTransit, 
  Delivered, 
  CreatedDone,
  OnTheWayDone,
  PreparedDone,
  InTransitDone,
  DeliveredDone,
  CreatedActive,
  OnTheWayActive,
  PreparedActive,
  InTransitActive,
  DeliveredActive,
  DeniedDelivered,
  CanceledDelivered,
  PartiallyDelivered,
} from '../../../../../../components/icons';

import { IconWrapper, AntdStepsStyled, ActivePulse } from './styles';

const { Step } = AntdSteps;

export interface StepProps {
  status: number;
  date: string;
}

interface StepsProps {
  steps?: Array<StepProps>;
  current: number;
}

const Steps: FC<StepsProps> = ({ steps, current }) => {
  
  const Icon = (icon: string) => {
    switch(icon) {
      case 'created': 
        if(current > 0) return <CreatedDone />;
        if(current === 0) return Active(<CreatedActive />);
        return <Created />

      case 'prepared': 
        if(current > 1) return <PreparedDone />;
        if(current === 1) return Active(<PreparedActive />);
        return <Prepared />

      case 'in-transit':
        if(current > 2) return <InTransitDone />;
        if(current === 2) return Active(<InTransitActive />);
        return <InTransit />

      case 'on-the-way':
        if(current > 3) return <OnTheWayDone />;
        if(current === 3) return Active(<OnTheWayActive />);
        return <OnTheWay />

      case 'delivered':
        if(current > 4) return <DeliveredDone />;
        if(current === 4) return Active(<DeliveredActive />);
        if(current === 5) return Active(<PartiallyDelivered />);
        if(current === 6) return Active(<DeniedDelivered />);
        if(current === 7) return Active(<CanceledDelivered />);
        return <Delivered />
    }
  }

  const Active = (icon: ReactElement) => {
    return <ActivePulse>{icon}</ActivePulse>;
  }

  const renderTitle = () => {
    if (steps && steps.length > 0) {
      switch (steps[steps.length - 1].status) {
        case 4:
          return "Entregue";
        case 5:
          return "Parcialmente entregue";
        case 6:
          return "Entrega recusada";
        case 7:
          return "Cancelado";
        default:
          return "Entregue";
      }
    } else {
      return "Entregue";
    }
  }

  const renderSteps = () => {
    const componentsSteps = [];

    for (var step = 0; step < 5; step++) {
      switch(step) {
        case 0:
          componentsSteps.push(
            <Step 
              title="Criado" 
              subTitle={steps && steps[step] && format(new Date(steps[step].date), 'dd/MM/yyyy') }
              icon={<IconWrapper>{Icon('created')}</IconWrapper>} 
            />
          );
          break;
        case 1:
          componentsSteps.push(
            <Step 
              title="Preparado" 
              subTitle={steps && steps[step] && format(new Date(steps[step].date), 'dd/MM/yyyy') }
              icon={<IconWrapper>{Icon('prepared')}</IconWrapper>}
            />
          );
          break;
        case 2:
          componentsSteps.push(
            <Step 
              title="Em trânsito" 
              subTitle={steps && steps[step] && format(new Date(steps[step].date), 'dd/MM/yyyy') }
              icon={<IconWrapper>{Icon('in-transit')}</IconWrapper>}
            />
          );
          break;
        case 3:
          componentsSteps.push(
            <Step 
              title="Indo até você" 
              subTitle={steps && steps[step] && format(new Date(steps[step].date), 'dd/MM/yyyy') } 
              icon={<IconWrapper>{Icon('on-the-way')}</IconWrapper>}
            />
          );
          break;
        case 4:
          componentsSteps.push(
            <Step 
              title={renderTitle()}
              subTitle={steps && steps[step] && format(new Date(steps[step].date), 'dd/MM/yyyy') }
              icon={<IconWrapper>{Icon('delivered')}</IconWrapper>}
            />
          );
          break;
      }
    }

    return componentsSteps;
  }
  
  return (
    <>
      <AntdStepsStyled current={current || 0} labelPlacement="vertical">
        {renderSteps()}
      </AntdStepsStyled>
    </>
  );
}

export default Steps;
