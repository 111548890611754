import api from '../../providers/axios';

import {
  IGetHistoryRequest,
  IGetHistoryResponse,
} from './dtos/IGetHistory';

export default class OrderService {

  public async getHistory(data: IGetHistoryRequest): Promise<IGetHistoryResponse> {
    if (data.filter) {
      return await api.get(`/history?skip=${data.skip}&take=${data.take}&filter=${data.filter}`);
    } else {
      return await api.get(`/history?skip=${data.skip}&take=${data.take}`);
    }
  }

  public async getHistoryByOrder(order: string): Promise<IGetHistoryResponse> {
    return await api.get(`/tracking/${order}`);
  }

}