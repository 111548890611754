function PreparedActive({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#0DA6F2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1288 10.6905L29.0903 14.1204C29.2364 14.1763 29.3334 14.3207 29.3334 14.4822V25.5177C29.3334 25.6792 29.2364 25.8236 29.0903 25.8796L20.1288 29.3095C20.0875 29.3253 20.0438 29.3333 20 29.3333C19.9567 29.3333 19.9131 29.3255 19.8713 29.3095L10.9099 25.8796C10.7638 25.8236 10.6667 25.6792 10.6667 25.5177V14.4822C10.6667 14.3561 10.7262 14.2379 10.826 14.1658C10.8522 14.1468 10.8805 14.1319 10.9098 14.1207L19.8714 10.6905C19.9544 10.6587 20.0457 10.6587 20.1288 10.6905Z" fill="#A3D6FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1288 10.6905L29.0903 14.1204C29.2364 14.1763 29.3334 14.3207 29.3334 14.4822V25.5177C29.3334 25.6792 29.2364 25.8236 29.0903 25.8796L20.1288 29.3095C20.0875 29.3253 20.0438 29.3333 20 29.3333C19.9567 29.3333 19.9131 29.3255 19.8713 29.3095L10.9099 25.8796C10.7638 25.8236 10.6667 25.6792 10.6667 25.5177V14.4822C10.6667 14.3561 10.7262 14.2379 10.826 14.1658C10.8522 14.1468 10.8805 14.1319 10.9098 14.1207L19.8714 10.6905C19.9544 10.6587 20.0457 10.6587 20.1288 10.6905ZM11.4104 25.2489L19.6282 28.3942V18.181L17.8433 17.4979V21.8867C17.8433 22.0128 17.7839 22.131 17.6841 22.2031C17.6208 22.2489 17.5465 22.2724 17.4714 22.2724C17.4281 22.2724 17.3845 22.2646 17.3427 22.2486L13.3268 20.7115C13.1807 20.6556 13.0837 20.5111 13.0837 20.3496V15.6762L11.4104 15.0357V25.2489ZM18.5417 16.9429L20.0001 17.501L27.8874 14.4823L20.0001 11.4635L12.1128 14.4822L13.4515 14.9947L17.3428 13.5053C17.4259 13.4735 17.5172 13.4735 17.6002 13.5053L21.6162 15.0423C21.7623 15.0983 21.8593 15.2426 21.8593 15.4042C21.8593 15.5658 21.7623 15.7101 21.6162 15.7661L18.5417 16.9429ZM20.372 28.3941L28.5897 25.2489V15.0357L20.372 18.181V28.3941Z" fill="#263465"/>
      <path d="M14.2117 16.9039C14.0879 16.8566 13.9505 16.9222 13.9047 17.0507C13.859 17.1792 13.9224 17.3218 14.0462 17.3692L16.0107 18.121C16.038 18.1315 16.0659 18.1364 16.0934 18.1364C16.1907 18.1364 16.282 18.0744 16.3177 17.9743C16.3634 17.8458 16.3 17.7032 16.1762 17.6558L14.2117 16.9039Z" fill="#A3D6FF"/>
      <path d="M14.2117 18.0564C14.0879 18.0091 13.9505 18.0747 13.9047 18.2032C13.859 18.3317 13.9224 18.4742 14.0462 18.5216L16.0107 19.2735C16.038 19.2839 16.0659 19.2889 16.0934 19.2889C16.1907 19.2889 16.282 19.2269 16.3177 19.1267C16.3634 18.9983 16.3 18.8557 16.1762 18.8083L14.2117 18.0564Z" fill="#A3D6FF"/>
      <path d="M13.9047 19.3557C13.9505 19.2272 14.0879 19.1615 14.2117 19.2089L16.1762 19.9608C16.3 20.0082 16.3634 20.1507 16.3177 20.2792C16.282 20.3794 16.1907 20.4414 16.0934 20.4414C16.0659 20.4414 16.038 20.4364 16.0107 20.426L14.0462 19.6741C13.9224 19.6267 13.859 19.4841 13.9047 19.3557Z" fill="#A3D6FF"/>
    </svg>
  );
}

export default PreparedActive;
