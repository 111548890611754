const censorWord = function (str: string | string[]) {
  return str[0] + '*'.repeat(str.length - 4) + str.slice(-1);
};

export const emailMask = function (email: string) {
  let arr = email.split('@');
  return censorWord(arr[0]) + '@' + censorWord(arr[1]);
};

export const phoneMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
    .replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
    .replace(/^(\d\d)(\d{0,5})/, '($1) $2');
};

const censorPhoneText = function (str: string | string[]) {
  return str[0] + '*'.repeat(str.length - 3) + str.slice(-2);
};

export const phoneCensor = function (phone: string) {
  let arr = phone.split('-');
  return censorPhoneText(arr[0]) + '-' + censorPhoneText(arr[1]);
};
