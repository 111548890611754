function InTransit({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6665 11.6278C10.6665 11.0211 11.0206 10.6666 11.6267 10.6666H17.6621C18.2681 10.6666 18.6223 11.0211 18.6223 11.6278V17.6692C18.6223 18.2759 18.2681 18.6304 17.6621 18.6304H11.6267C11.0206 18.6304 10.6665 18.2759 10.6665 17.6692V11.6278ZM11.6267 11.4905H12.9298V13.0695C12.9298 13.3728 13.1754 13.6187 13.4785 13.6187H15.8103C16.1133 13.6187 16.359 13.3728 16.359 13.0695V11.4905H17.6621C17.7378 11.4905 17.7993 11.5519 17.7993 11.6278V17.6692C17.7993 17.7451 17.7378 17.8065 17.6621 17.8065H11.6267C11.5509 17.8065 11.4895 17.7451 11.4895 17.6692V11.6278C11.4895 11.5519 11.5509 11.4905 11.6267 11.4905ZM13.6845 12.8635V11.4905H15.6048V12.8635H13.6845Z" fill="#757575"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3774 22.3307C21.3774 21.724 21.7315 21.3695 22.3376 21.3695H28.373C28.979 21.3695 29.3332 21.724 29.3332 22.3307V28.3721C29.3332 28.9788 28.979 29.3333 28.373 29.3333H22.3376C21.7315 29.3333 21.3774 28.9788 21.3774 28.3721V22.3307ZM22.3376 22.1934H23.6407V23.7724C23.6407 24.0757 23.8863 24.3216 24.1894 24.3216H26.5212C26.8242 24.3216 27.0699 24.0757 27.0699 23.7724V22.1934H28.373C28.4487 22.1934 28.5102 22.2548 28.5102 22.3307V28.3721C28.5102 28.448 28.4487 28.5095 28.373 28.5095H22.3376C22.2618 28.5095 22.2004 28.448 22.2004 28.3721V22.3307C22.2004 22.2548 22.2618 22.1934 22.3376 22.1934ZM24.3954 23.5664V22.1934H26.3157V23.5664H24.3954Z" fill="#757575"/>
      <path d="M26.3865 11.3873C26.2173 11.2157 25.9407 11.2157 25.7715 11.3873C25.6056 11.5557 25.6056 11.8262 25.7715 11.9946L27.9572 14.2125L21.8121 14.2125C21.572 14.2125 21.3774 14.4073 21.3774 14.6476C21.3774 14.8879 21.572 15.0827 21.8121 15.0827L27.9578 15.0827L25.7715 17.3012C25.6056 17.4696 25.6056 17.7401 25.7715 17.9084C25.9407 18.08 26.2173 18.08 26.3865 17.9084L29.1446 15.1096C29.3959 14.8546 29.3959 14.4412 29.1446 14.1862L26.3865 11.3873Z" fill="#757575"/>
      <path d="M14.2293 28.6116C14.0602 28.7833 13.7835 28.7833 13.6144 28.6116L10.8563 25.8129C10.605 25.5579 10.605 25.1445 10.8563 24.8895L13.6144 22.0907C13.7835 21.9191 14.0602 21.9191 14.2293 22.0907C14.3952 22.2591 14.3952 22.5296 14.2293 22.6979L12.0431 24.9164L18.1887 24.9164C18.4287 24.9164 18.6233 25.1112 18.6233 25.3515C18.6233 25.5918 18.4287 25.7866 18.1887 25.7866L12.0437 25.7866L14.2293 28.0044C14.3952 28.1728 14.3952 28.4433 14.2293 28.6116Z" fill="#757575"/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#9E9E9E"/>
    </svg>
  );
}

export default InTransit;
