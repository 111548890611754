import styled, { css } from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import Container from '../container';
import Text from '../text';
import Button from '../button';

import { Logo, ListView } from '../icons';

interface HeaderProps {
  hasHome?: boolean;
}

const { Item, Divider } = Menu;

const common = css`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.md`
    height: 64px;
  `}
`;

export const InternalWrapper = styled.div`
  background: linear-gradient(99.99deg, #161f3b 2.94%, #1862a3 81.08%);
`;

export const ContainerStyled = styled(Container)``;

export const LogoStyled = styled(Logo)<HeaderProps>`
  fill: ${({ theme }) => theme.colors.white};
  transition: ease 0.2s;
  width: 108px;

  ${({ theme }) => theme.media.md`
  fill: ${({ theme }: any) => theme.colors.white};
  width: 94.89px;
  
  ${({ hasHome }: HeaderProps) =>
    hasHome &&
    css`
      fill: ${({ theme }: any) => theme.colors.blue.dark};
    `}
  `}
`;

export const PositionContent = styled.div`
  ${common}

  ${({ theme }) => theme.media.md`
    svg {
      margin: auto;
    }
  `}
`;

export const AvatarWrapper = styled.div`
  ${common}
  max-width: 239px;

  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserInitials = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cce8ff;
  margin-right: 8px;

  span {
    color: #263465;
  }
`;

export const UserName = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 14px;
`;

export const LogoutStyled = styled(LogoutOutlined)``;

export const DropDownStyled = styled(Dropdown)``;

export const MobileMenu = styled(MoreOutlined)`
  display: none;
  color: white;
  cursor: pointer;

  ${({ theme }) => theme.media.md`
    display: block;
  `}
`;

export const MenuStyled = styled(Menu)`
  background: #161f3b;
  width: 243px;
  padding: 24px 24px 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ItemStyled = styled(Item)`
  padding: 0;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ListViewStyled = styled(ListView)`
  margin: 0 17px 0 7px;
  fill: ${({ theme }) => theme.colors.white};
  width: 17px;
  height: 14px;
`;

export const DividerStyled = styled(Divider)`
  background: ${({ theme }) => theme.colors.grey.darkness};
`;

export const ButtonStyled = styled(Button)`
  font-weight: 500;
`;
