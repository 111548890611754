import { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useParams } from 'react-router-dom';
import { Base64 } from "js-base64";
import axios from 'axios';

import SessionService from '../../services/SessionService';

import { useNavigationInfos } from '../../hooks/navigationInfos';

import Container from '../../components/container';
import Layout from '../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  WelcomeContainer,
} from './styles';

const ConsultByLink = () => {
  const [animated, setAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reCaptcha, setReCaptcha] = useState<string>();
  const reCaptchaRef = useRef<any>();

  const { setResetToken, setNf } = useNavigationInfos();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    console.log(JSON.parse(Base64.decode(token || '')));
  }, [])
  

  const handleClick = async () => {
    setAnimated(true);
    setIsLoading(true);

    const { nf, email } = JSON.parse(Base64.decode(token || ''));

    const sessionService = new SessionService();

    if (reCaptcha) {
      try {
        
        const response = await sessionService.TrackInvoiceSession({
          contact: email, 
          nf, 
          captchaToken: reCaptcha
        });
        
        if (response) {
          setResetToken(response.data.resetToken);
        }
        
        setNf(nf);
        navigate('/confirm-token');
      } catch(err) {
        console.log(err);
        setIsLoading(false);
        reCaptchaRef.current.reset();
        setReCaptcha(undefined);


        //setShowError(true);
        //setErrorMessage('Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.');
      }
    }
  };

  return (
    <Layout mode={"external"}>
      <WelcomeContainer>
        <Container>
          <Content animated={animated}>
            <HeadingStyled type="h1">Tracking Luft</HeadingStyled>
            <HeadingStyled type="h2">Confira a posição da sua nota</HeadingStyled>

            <Description>
              Por segurança, precisamos enviar um código de verificação no e-mail
              e celular cadastrados para confirmar a sua identidade.
            </Description>

            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
              onChange={(value: any) => {
                setReCaptcha(`${value}`);
              }}
            />

            <ButtonStyled
              mode="default"
              onClick={handleClick}
              isLoading={isLoading}
              disabled={!reCaptcha}
            >
              Enviar código
            </ButtonStyled>
          </Content>
        </Container>
      </WelcomeContainer>
    </Layout>
  );
};

export default ConsultByLink;
