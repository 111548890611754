import { FC } from 'react';

import {
  ExternalWrapper,
  InternalWrapper,
  ContainerStyled,
  Content,
  FirstContent,
  SecondContent,
  FooterTitle,
  FooterDescription,
  InfoContainer,
  InfoContent,
  IconContent,
  MailStyled,
  PhoneStyled,
  InfoText,
} from './styles';

interface FooterProps {
  show?: boolean;
  mode: 'internal' | 'external';
}

const Footer: FC<FooterProps> = ({ show, mode, ...props }) => {
  return (
    <footer>
      {mode === 'external' &&
        (!show ? null : (
          <ExternalWrapper {...props}>
            <ContainerStyled>
              <span>©Luft. 2022. Todos os direitos reservados.</span>
            </ContainerStyled>
          </ExternalWrapper>
        ))}

      {mode === 'internal' && (
        <InternalWrapper>
          <ContainerStyled>
            <Content>
              <FirstContent>
                <FooterTitle>Algum problema com sua entrega?</FooterTitle>
                <FooterDescription>
                  Por favor entre em contato:
                </FooterDescription>
                <InfoContainer>
                  <InfoContent>
                    <IconContent>
                      <PhoneStyled />
                    </IconContent>
                    <InfoText>(11) 4772-4210</InfoText>
                  </InfoContent>
                  <InfoContent>
                    <IconContent>
                      <MailStyled />
                    </IconContent>
                    <InfoText><a href='mailto:Sac@luftagro.com.br'>Sac@luftagro.com.br</a></InfoText>
                  </InfoContent>
                </InfoContainer>
              </FirstContent>
              <SecondContent>
                <span>©Luft. 2022. Todos os direitos reservados.</span>
              </SecondContent>
            </Content>
          </ContainerStyled>
        </InternalWrapper>
      )}
    </footer>
  );
};

export default Footer;
