function OnTheWayDone({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#A3D6FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9995 20.313L17.3152 16.7951L17.1661 16.9062L16.723 17.2347L16.5003 17.4005L13.8188 19.3918L13.5961 19.5557L13.5442 19.5952C13.536 19.6016 13.5254 19.6035 13.5156 19.6004C13.5058 19.5973 13.4979 19.5896 13.4943 19.5795C13.3875 19.2867 13.333 18.9774 13.3333 18.6657C13.3334 17.1915 14.5296 16 16.0001 16C16.3499 15.9999 16.6964 16.0687 17.0198 16.2025C17.3431 16.3362 17.637 16.5324 17.8847 16.7798C18.1325 17.0272 18.3291 17.3211 18.4633 17.6447C18.5975 17.9682 18.6666 18.3152 18.6667 18.6656C18.6667 20.1393 17.4706 21.3333 16.0001 21.3333C15.5078 21.3334 15.025 21.1971 14.6053 20.9395C14.1856 20.6819 13.8453 20.3129 13.6224 19.8733C13.6142 19.8572 13.6192 19.8371 13.6338 19.8273C13.6411 19.8224 13.6483 19.8199 13.6522 19.8185C13.6566 19.8169 13.6586 19.8159 13.6586 19.8159L13.6662 19.8109L13.6799 19.8079L13.6943 19.8033L13.7425 19.7876C13.7968 19.7698 13.852 19.7517 13.9067 19.737C14.902 19.4452 15.6961 19.5439 16.0981 19.6734C16.5432 19.8197 16.8769 20.1704 16.9995 20.313ZM13.663 19.8502L13.6514 19.8568C13.6549 19.8544 13.6593 19.8515 13.663 19.8502Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6517 16.5053C24.6517 16.3638 24.7723 16.2491 24.921 16.2491H27.9242C28.0491 16.2491 28.1663 16.3061 28.2395 16.4024L30.1237 18.8821C30.2525 19.0516 30.1251 19.2884 29.9053 19.2883L24.9209 19.2853C24.7722 19.2852 24.6517 19.1706 24.6517 19.0291V16.5053ZM25.4564 16.9267V18.5885H28.9839L27.6641 16.9267H25.4564Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.02702 12L23.591 12C23.8263 12 24.02 12.1715 24.02 12.3865V14.8646H28.4729C28.6108 14.8646 28.7407 14.9243 28.8215 15.0259L31.9197 18.9202C31.9718 18.9857 32 19.0645 32 19.1454V24.9876C32 25.2026 31.8063 25.3742 31.5711 25.3742H29.3299C29.3366 25.4434 29.34 25.5136 29.34 25.5844C29.34 26.92 28.134 28.1333 26.65 28.1333C25.1661 28.1333 23.9601 26.92 23.9601 25.5844C23.9601 25.5136 23.9635 25.4434 23.9701 25.3742H16.8276C16.8342 25.4434 16.8376 25.5136 16.8376 25.5844C16.8376 26.92 15.6317 28.1333 14.1477 28.1333C12.6638 28.1333 11.4578 26.92 11.4578 25.5844C11.4578 25.5136 11.4612 25.4434 11.4678 25.3742H8.42893C8.1937 25.3742 8 25.2026 8 24.9876V12.965C8.00001 12.7215 8.12938 12.4804 8.32057 12.3009C8.51179 12.1213 8.76825 12 9.02702 12ZM9.03736 12.7731C9.03455 12.7739 9.03001 12.7755 9.02375 12.7783C9.00188 12.7879 8.97105 12.8066 8.93957 12.8361C8.90825 12.8656 8.88525 12.8973 8.87151 12.925C8.85898 12.9502 8.85786 12.9652 8.85786 12.9652L8.85786 24.6011H11.69C12.1096 23.7565 13.0527 23.04 14.1477 23.04C15.2427 23.04 16.1858 23.7565 16.6054 24.6011H23.1621V12.7731L9.03736 12.7731ZM24.02 15.6376V24.6011H24.1923C24.612 23.7565 25.555 23.04 26.65 23.04C27.745 23.04 28.6881 23.7565 29.1078 24.6011H31.1421V19.2697L28.2526 15.6376H24.02ZM14.1477 23.8133C13.1342 23.8133 12.3156 24.6788 12.3156 25.5844C12.3156 26.49 13.1342 27.36 14.1477 27.36C15.1612 27.36 15.9798 26.49 15.9798 25.5844C15.9798 24.6788 15.1612 23.8133 14.1477 23.8133ZM26.65 23.8133C25.6365 23.8133 24.818 24.6788 24.818 25.5844C24.818 26.49 25.6365 27.36 26.65 27.36C27.6635 27.36 28.4821 26.49 28.4821 25.5844C28.4821 24.6788 27.6635 23.8133 26.65 23.8133Z" fill="#161F3B"/>
    </svg>
  );
}

export default OnTheWayDone;
