import { FC } from 'react';
import { Field, FieldHookConfig, useField } from 'formik';

import theme from '../../constants/theme';

import {
  Input,
  Label,
  Wrapper,
  TextError,
  TooltipStyled,
  InputContainer,
  QuestionStyled,
  LabelContainer,
  StyledMaskedInput,
} from './styles';

interface InputfieldProps {
  mask?: string | RegExp | null;
  label: string;
  type?: string;
  disabled?: boolean;
  placeholder: string;
  tooltipLabel?: string;
  maskChar?: string | null;
}

const Inputfield: FC<InputfieldProps & FieldHookConfig<any>> = ({
  mask,
  type,
  label,
  maskChar,
  disabled,
  placeholder,
  tooltipLabel,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Wrapper>
      <LabelContainer>
        <Label>{label}</Label>
        {tooltipLabel && (
          <TooltipStyled title={tooltipLabel} color={theme.colors.blue.dark}>
            <QuestionStyled />
          </TooltipStyled>
        )}
      </LabelContainer>
      <InputContainer disabled={disabled} error={meta.touched && meta.error}>
        {!mask ? (
          <Input
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            error={meta.touched && meta.error}
            {...field}
            {...props}
          />
        ) : (
          <Field        
            {...props}
           >
            {({ field }: any) => (
              <StyledMaskedInput
                {...field}
                type={type}
                mask={mask}
                maskChar={maskChar}
                disabled={disabled}
                placeholder={placeholder}
                error={meta.touched && meta.error}
              />
            )}
           </Field>
        )}
      </InputContainer>
      {meta.touched && meta.error ? (
        
        <TextError hasContent>{meta.error}</TextError>
      ) : (
        <TextError>{''}</TextError>
      )}
    </Wrapper>
  );
};

export default Inputfield;
