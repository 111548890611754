import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/layout';

import { useAuth } from '../../../hooks/auth';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  ContainerStyled,
} from '../styles';

const TimeOut = () => {
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const handleLogOut = () => {
    logOut();
    navigate('/home');
  }

  return (
    <Layout mode={"external"}>
      <ContainerStyled>
        <Content>
          <HeadingStyled type="h1">Você foi desconectado...</HeadingStyled>

          <Description>
            Por segurança, você foi desconectado. Caso deseje voltar a consultar
            sua nota, por favor, autentique-se novamente.
          </Description>

          <ButtonStyled decrease mode="default" onClick={handleLogOut}>
            Entrar novamente
          </ButtonStyled>
        </Content>
      </ContainerStyled>
    </Layout>
  );
};

export default TimeOut;
