import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  Description,
  ContainerStyled,
} from '../styles';

const APIError = () => {
  const navigate = useNavigate();

  return (
    <Layout mode={"external"}>
      <ContainerStyled>
        <Content>
          <HeadingStyled type="h1">Ops. Algo deu errado...</HeadingStyled>

          <Description>
            Nossos serviços estão indisponíveis nesse momento. Por favor, tente
            novamente mais tarde.
          </Description>

          <ButtonStyled mode="default" onClick={() => navigate('/')}>
            Ir para a página inicial
          </ButtonStyled>
        </Content>
      </ContainerStyled>
    </Layout>
  );
};

export default APIError;
