import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExternalPaths, InternalPaths } from '../../constants/routes';
import { useAuth } from '../../hooks/auth';

import {
  AvatarWrapper,
  ButtonStyled,
  ContainerStyled,
  DividerStyled,
  InternalWrapper,
  ExternalWrapper,
  PositionContent,
  ItemStyled,
  ListItem,
  LogoStyled,
  MenuStyled,
  MobileMenu,
  UserInitials,
  UserName,
  UserWrapper,
  ListViewStyled,
  LogoutStyled,
  DropDownStyled,
} from './styles';

interface HeaderProps {
  mode: 'internal' | 'external';
}

const Header: FC<HeaderProps> = ({ mode }) => {
  const { user, logOut } = useAuth();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const initialName = user?.name.match(/\b(\w)/g);

  const formatInitialName = initialName && initialName.join('').substring(0, 2);

  const StyledByPathName = (pathname: string) => {
    switch (pathname) {
      case ExternalPaths.INDEX:
        return true;
      case ExternalPaths.LINK:
        return true;
      case ExternalPaths.REGISTER:
        return true;
      default:
        return false;
    }
  };

  const handleNFList = () => {
    navigate(InternalPaths.INDEX); // TODO
  };

  const handleLogout = () => {
    logOut();

    navigate(ExternalPaths.INDEX); // TODO
  };

  const menu = (
    <MenuStyled>
      <ItemStyled key="0">
        <ListItem>
          <UserInitials>
            <span>{formatInitialName}</span>
          </UserInitials>
          <UserName>{user?.name}</UserName>
        </ListItem>
      </ItemStyled>
      <ItemStyled key="1">
        <ListItem
          onClick={handleNFList}
        >
          <ListViewStyled />
          <UserName>Lista de notas</UserName>
        </ListItem>
      </ItemStyled>
      <DividerStyled />
      <ItemStyled key="3">
        <ButtonStyled
          mode="action"
          rightElement={<LogoutStyled />}
          onClick={handleLogout}
        >
          Sair
        </ButtonStyled>
      </ItemStyled>
    </MenuStyled>
  );

  return (
    <header>
      {mode === 'external' && (
        <ExternalWrapper>
          <ContainerStyled>
            <LogoStyled hasHome={StyledByPathName(pathname)} />
          </ContainerStyled>
        </ExternalWrapper>
      )}

      {mode === 'internal' && (
        <InternalWrapper>
          <ContainerStyled>
            <PositionContent>
              <LogoStyled />
              <AvatarWrapper>
                <UserWrapper>
                  <UserInitials>
                    <span>{formatInitialName}</span>
                  </UserInitials>
                  <UserName>{user?.name}</UserName>
                </UserWrapper>
                <ButtonStyled
                  mode="action"
                  rightElement={<LogoutStyled />}
                  onClick={handleLogout}
                >
                  Sair
                </ButtonStyled>
              </AvatarWrapper>
              <DropDownStyled overlay={menu} trigger={['click']}>
                <MobileMenu />
              </DropDownStyled>
            </PositionContent>
          </ContainerStyled>
        </InternalWrapper>
      )}
    </header>
  );
};

export default Header;
