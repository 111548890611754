import styled from 'styled-components';
import { List } from 'antd';

export const EmptyContent = styled.div`
  height: calc(100vh - 21.9rem);
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListStyled = styled(List)<{ amount: boolean }>`
  margin-top: 32px;

  height: ${({ amount }) => amount && 'calc(100vh - 21.9rem)'};

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ${({ theme }) => theme.media.md`
    height: unset;
  `}
`;

export const WrapperEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  ${({ theme }) => theme.media.md`
      margin-top: 16px;
      padding: 16px 0px;
    }
  `}

  margin-top: 40px;
  padding: 64px 0px;
  
  border: dashed 2px ${({ theme }) => theme.colors.blue.light};

  color: ${({ theme }) => theme.colors.blue.darkness};

  span {
    border-radius: 4px;
    
    padding: 0px 16px;
    background-color: white;
  }
`;

export const LoaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
`;
