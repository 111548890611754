import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Row } from 'antd';
import axios from 'axios';

import {
  CloseOutlined,
  LoadingOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Steps from './components/Steps';
import Map from '../../../../components/map';
import Carousel from './components/Carousel';
import OrderStatus from './components/OrderStatus';

import { Phone } from '../../../../components/icons';

import {
  Wrapper,
  Content,
  EmptyMap,
  StyledLink,
  MapWrapper,
  InfoWrapper,
  MapLocation,
  MainContent,
  EmptyMapText,
  ContactPhone,
  ReceiptTitle,
  OverlayHeader,
  OverlayButton,
  DetailWrapper,
  InvoiceNumber,
  ContentWrapper,
  ContentContact,
  LoadingWrapper,
  ContentSubtitle,
  CarouselWrapper,
  CarouselOverlay,
  MapLocationMobile,
  ContactDescription,
  CarouselOverlayWrapper,
} from './styles';

import OrderService from '../../../../services/OrderService';

import { STEP_COLUMN } from '../../../../constants/stepTracking';
import ConvertSteps from '../../../../utils/ConvertSteps';
import Layout from '../../../../components/layout';
import { StepProps } from './components/Steps';

import { useAuth } from '../../../../hooks/auth';

export interface nfStatus{
  CNPJ_Destinatario: string,
  CNPJ_Emissor_NF: string,
  DataHora_Encerramento: string,
  DataHora_Status: string,
  FilialDestino?: string,
  FilialOrigem?: string,
  Id: string,
  InvoiceId: string,
  IsTms: boolean,
  Manifesto?: string,
  Nota_Fiscal: string,
  NumPedido?: string,
  Serie_NF: string,
  status: number,
  status_codigo: number,
}

interface MapInfoProps{
  initial: {
    lat: number;
    lng: number;
  }
  destinationMarker?: {
    lat: number;
    lng: number;
  }
  truckMarker?: {
    lat: number;
    lng: number;
  }
}

interface manifest {
  destinationAddress: string,
  destinationCity: string,
  destinationState: string,
  orderStatus: number,
}

interface Receipt {
  id: number;
  url: string;
}

interface InvoiceProps {
  id: number;
  serie: number;
  deliveryDate: string;
  estimatedDeliveryTime: string;
  issuer: string;
  volumes: string;
  truckData: any;
  nfStatus: Array<nfStatus>;
  manifestData: manifest;
  recipient?: string;
  hasTrackingMap?: boolean;
  receipts?: Array<Receipt>;
  nfs: Array<string>;
}

const NFDetail = () => {
  const [mapInfo, setMapInfo] = useState<MapInfoProps | undefined>();
  
  const mapRef = useRef<any>(null);
  const { id } = useParams();
  
  const [showCarouselOverlay, setShowCarouselOverlay] = useState(false);
  const [carouselOverlayCurrentPage, setCarouselOverlayCurrentPage] =
    useState(0);
  const [carouselCurrentPage, setCarouselCurrentPage] = useState(0);
  const [invoice, setInvoice] = useState<InvoiceProps>();
  const [steps, setSteps] = useState<Array<StepProps>>();
  const [hasReceipt, setHasReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [scrollTop, setScrollTop] = useState(56);

  const { user } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setScrollTop(currentScrollY > 56 ? 0 : 56 - currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (invoice) {
      const stepStatus = ConvertSteps(invoice.manifestData.orderStatus);
      TMSStatusToSteps();

      if (
        stepStatus === STEP_COLUMN.DELIVERED ||
        stepStatus === STEP_COLUMN.PARTIALLY_DELIVERED
      ) {
        setHasReceipt(true);
        setErrorMessage(['Visualização de mapa indisponível no momento.', 'De toda forma, seu pedido foi entregue! :)']);
        if (invoice.truckData.destinationLatitude && invoice.truckData.destinationLongitude) {
          setMapInfo({
            initial: {
              lat: invoice.truckData.destinationLatitude,
              lng: invoice.truckData.destinationLongitude,
            },
            destinationMarker: {
              lat: invoice.truckData.destinationLatitude,
              lng: invoice.truckData.destinationLongitude,
            }
          })
        }
      } else {
        if (
          stepStatus === STEP_COLUMN.ON_THE_WAY &&
          invoice.truckData.destinationLatitude && 
          invoice.truckData.destinationLongitude &&
          invoice.truckData.latitude &&
          invoice.truckData.longitude) {
          setMapInfo({
            initial: {
              lat: invoice.truckData.latitude,
              lng: invoice.truckData.longitude,
            },
            destinationMarker: {
              lat: invoice.truckData.destinationLatitude,
              lng: invoice.truckData.destinationLongitude,
            },
            truckMarker: {
              lat: invoice.truckData.latitude,
              lng: invoice.truckData.longitude,
            }
          })
        }
        setErrorMessage(['Quando seu pedido estiver indo até você,', 'é aqui que você vai poder acompanhá-lo', 'em tempo real! ;)']);
        if (
          stepStatus === STEP_COLUMN.ON_THE_WAY &&
          invoice.truckData.destinationLatitude && 
          invoice.truckData.destinationLongitude &&
          !invoice.truckData.latitude &&
          !invoice.truckData.longitude) {
            setErrorMessage(['Visualização do pedido indisponível no momento.', 'De toda forma, seu pedido está indo até você! :)']);
        }
      }
    }
  }, [invoice]);

  useEffect(() => {
    requestGetHistoryByOrder(String(id))
  }, []);

  const requestGetHistoryByOrder = async (value: string) => {
    const requestHttp = new OrderService();

    try {
      const response = await requestHttp.getHistoryByOrder(value);

      setInvoice(response.data);
    } catch(err) {
      if (axios.isAxiosError(err)) {
        console.log(err);
      } else {
        console.log(err);
      } 
    }
  }

  const TMSStatusToSteps = () => {
    const arraySteps: Array<StepProps> = [];
    const arrayConvertSteps: Array<StepProps> = [];

    let firstItem = false;

    invoice?.nfStatus.forEach((item) => {
      if (ConvertSteps(item.status) !== STEP_COLUMN.IGNORE && item.DataHora_Encerramento) {
        const stepIndex = arrayConvertSteps.findIndex(object => ConvertSteps(item.status) === object.status);

        if (stepIndex === -1 && !firstItem) {
          arrayConvertSteps.push({
            status: 0,
            date: item.DataHora_Encerramento
          } as StepProps);

          firstItem = true;
        }

        if (stepIndex === -1) {
          arrayConvertSteps.push({
            status: ConvertSteps(item.status),
            date: item.DataHora_Encerramento
          } as StepProps);
        }

        if (stepIndex !== -1 && new Date(item.DataHora_Encerramento).getTime() < new Date(arrayConvertSteps[stepIndex].date).getTime()) {
          arrayConvertSteps[stepIndex] = {
            status: ConvertSteps(item.status),
            date: arrayConvertSteps[stepIndex].date
          } as StepProps;
        }

        if (stepIndex !== -1 && new Date(item.DataHora_Encerramento).getTime() > new Date(arrayConvertSteps[stepIndex].date).getTime()) {
          arrayConvertSteps[stepIndex] = {
            status: ConvertSteps(item.status),
            date: item.DataHora_Encerramento
          } as StepProps;
        }
      }
    });
    
    arrayConvertSteps.sort(function(a, b) {
      return a.status - b.status;
    });
    
    if (arrayConvertSteps) {
      let step = 0;

      if (arrayConvertSteps.length === 2 && arrayConvertSteps[0].status === 0 && arrayConvertSteps[1].status === 4) {
        for (var i = 0; i < 5; i++) {
          arraySteps.push({
            status: i,
            date: arrayConvertSteps[0].date,
          });
        }
      } else {
        arrayConvertSteps.forEach((item) => {
          if (step === 0 && item.status !== step && item.status === 1){
            arraySteps.push({
              status: step,
              date: arrayConvertSteps[0].date
            }, item);
            step = step + 2;
          } else 
          if (step === 0 && item.status !== step && item.status === 2){
            arraySteps.push({
              status: step,
              date: arrayConvertSteps[0].date
            },
            {
              status: step + 1,
              date: arrayConvertSteps[0].date
            }, item);
            step = step + 3;
          } else if (step === 0 && item.status !== step && item.status === 3){
            arraySteps.push({
              status: step,
              date: arrayConvertSteps[0].date
            },
            {
              status: step + 1,
              date: arrayConvertSteps[0].date
            },
            {
              status: step + 2,
              date: arrayConvertSteps[0].date
            }, item);
            step = step + 4;
          } else if (item.status > step) {
            arraySteps.push({
              status: step,
              date: arraySteps[step - 1].date
            }, item);
            step = step + 2;
          } else if (item.status < step){
            arraySteps.splice(step, step - 1, {
              status: step,
              date: arraySteps[step - 1].date
            } as StepProps);
            step++
          }  else if (arrayConvertSteps){
            arraySteps.push(item);
            step++
          }
        });
      }
    }

    setSteps(arraySteps);
  }
  
  return (
    <Layout mode={"internal"}>
      <Wrapper>
        <Content>
          <InfoWrapper>
            <StyledLink to="/">
              <ArrowLeftOutlined />
              <span>Lista de entregas</span>
            </StyledLink>

            <DetailWrapper>
              <InvoiceNumber>CTE {id}</InvoiceNumber>
            </DetailWrapper>

            {invoice ? (
              <MainContent>
                <ContentWrapper>
                  <Steps current={ConvertSteps(invoice.manifestData.orderStatus)} steps={steps} />
                </ContentWrapper>

                <ContentWrapper>
                  <OrderStatus
                    sender={invoice.issuer}
                    status={ConvertSteps(invoice.manifestData.orderStatus)}
                    address={`${invoice.manifestData.destinationAddress} - ${invoice.manifestData.destinationCity} - ${invoice.manifestData.destinationState}`}
                    volumes={invoice.volumes}
                    forecast={format(new Date(invoice.estimatedDeliveryTime), 'dd/MM/yyyy')}
                    recipient={invoice.recipient}
                    nfStatus={invoice.nfStatus}
                    nfs={invoice.nfs}
                  />
                </ContentWrapper>

                {user && user.hasTrackingMap && (
                  <MapWrapper>
                    <MapLocationMobile ref={mapRef} scroll={scrollTop}>
                      {mapInfo ? (
                        <Map data={mapInfo} />
                      ) : (
                        <EmptyMap>
                          {
                            errorMessage.map((item, key) => (
                              <EmptyMapText key={key}>{item}</EmptyMapText>
                            ))
                          }
                        </EmptyMap>
                      )}
                    </MapLocationMobile>
                  </MapWrapper>
                )}

                {hasReceipt && (
                  <ContentWrapper>
                    <ReceiptTitle>Comprovantes</ReceiptTitle>
                    <CarouselWrapper>
                      <Carousel
                        images={invoice?.receipts ? invoice.receipts : []}
                        setOverlay={setShowCarouselOverlay}
                        currentPage={carouselCurrentPage}
                        setCurrentPage={setCarouselCurrentPage}
                      />
                    </CarouselWrapper>
                  </ContentWrapper>
                )}

                <ContentWrapper>
                  <ContentContact>
                    <ContentSubtitle>
                      Algum problema com essa entrega?
                    </ContentSubtitle>
                    <ContactDescription>
                      Por favor entre em contato:
                    </ContactDescription>
                    <Row>
                      <Phone />
                      <ContactPhone>(11) 4772-4210</ContactPhone>
                    </Row>
                  </ContentContact>
                </ContentWrapper>
              </MainContent>
            ) : (
              <LoadingWrapper>
                <LoadingOutlined />
              </LoadingWrapper>
            )}
          </InfoWrapper>
          {(user && user.hasTrackingMap) && (
            <MapLocation ref={mapRef} scroll={scrollTop}>
              {mapInfo ? (
                <Map data={mapInfo} />
              ) : (
                <EmptyMap>
                  {
                    errorMessage.map((item, key) => (
                      <EmptyMapText key={key}>{item}</EmptyMapText>
                    ))
                  }
                </EmptyMap>
              )}
            </MapLocation>
          )}
        </Content>
      </Wrapper>

      {showCarouselOverlay && hasReceipt && (
        <CarouselOverlay>
          <OverlayHeader>
            <OverlayButton onClick={() => setShowCarouselOverlay(false)}>
              <ArrowLeftOutlined /> Nota {id}
            </OverlayButton>
            <OverlayButton onClick={() => setShowCarouselOverlay(false)}>
              <CloseOutlined />
            </OverlayButton>
          </OverlayHeader>
          <CarouselOverlayWrapper>
            <Carousel
              images={invoice?.receipts ? invoice.receipts : []}
              setOverlay={setShowCarouselOverlay}
              currentPage={carouselOverlayCurrentPage}
              setCurrentPage={setCarouselOverlayCurrentPage}
              isCarouselOverlay={showCarouselOverlay}
            />
          </CarouselOverlayWrapper>
        </CarouselOverlay>
      )}
    </Layout>
  );
};

export default NFDetail;
