import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Breakpoints } from '../../constants/theme';

import GlobalStyle from '../../constants/global';
import Background from '../background';
import Header from '../header';
import Footer from '../footer';
import { ExternalPaths } from '../../constants/routes';

interface LayoutProps {
  mode?: 'internal' | 'external';
}

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: unset;

  ${({ theme }) => theme.media.md`
    height: 100vh;
  `}
`;

const Layout: FC<LayoutProps> = ({ mode, children }) => {
  const [hasDimension, setHasDimension] = useState(true);
  const { pathname } = useLocation();

  const { width } = useWindowDimensions();

  const BgByPathName = (pathname: string) => {
    switch (pathname) {
      case ExternalPaths.INDEX:
        return true;
      case ExternalPaths.LINK:
        return true;
      case ExternalPaths.REGISTER:
        return true;
      default:
        return false;
    }
  };

  const FooterByPathName = (pathname: string) => {
    switch (pathname) {
      case ExternalPaths.REGISTER_FORM:
        return <Footer show={!!hasDimension} mode="external" />;
      default:
        return <Footer show mode="external" />;
    }
  };

  useEffect(() => {
    if (width < Breakpoints.md) {
      setHasDimension(false);
    } else {
      setHasDimension(true);
    }
  }, [width]);

  return (
    <>
      <GlobalStyle />
      
      {mode === 'external' && (
        <Background bgOpacity={BgByPathName(pathname)}>
          <ContainerStyled>
            <Header mode="external" />
            <main>{children}</main>
            {FooterByPathName(pathname)}
          </ContainerStyled>
        </Background>
      )}

      {mode === 'internal' && (
        <>
          <Header mode="internal" />
          <main>{children}</main>
          <Footer mode="internal" />
        </>
      )}
    </>
  );
};

export default Layout;
