import * as Yup from 'yup';
import { validateCpf, validateCnpj } from './validation';
import { AnyObject, Maybe } from 'yup/lib/types';

function cpf(this: any, message: any) {
  return this.test({
    name: 'cpf',
    exclusive: true,
    message,
    test: validateCpf,
  });
}

function cnpj(this: any, message: any) {
  return this.test({
    name: 'cnpj',
    exclusive: true,
    message,
    test: validateCnpj,
  });
}

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
    cpf(errorMessage: string): StringSchema<TType, TContext>;
    cnpj(errorMessage: string): StringSchema<TType, TContext>;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'cpf', cpf);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'cnpj', cnpj);

export default Yup;
