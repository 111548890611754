import axios from 'axios';
import LocalStorage from '../../constants/LocalStorage';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem(LocalStorage.SESSION_TOKEN);

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return config;
});

export default api;
