import { FC, ReactNode } from 'react';

import { TextStyled } from './styles';

interface TextProps {
  children: ReactNode;
}

const Text: FC<TextProps> = ({ children, ...rest }) => {
  return <TextStyled {...rest}>{children}</TextStyled>;
};

export default Text;
