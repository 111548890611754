import React from 'react';
import { RouteProps, Navigate, Outlet } from 'react-router-dom';

import { ExternalPaths } from '../constants/routes';
import { useAuth } from '../hooks/auth';

interface RouteComponentProps extends Omit<RouteProps, 'element'> {

}

const RouteComponent: React.FC<RouteComponentProps> = ({ ...props }) =>  {
  const { token } = useAuth();

  return token ? <Outlet /> : <Navigate to={{ pathname: ExternalPaths.INDEX }} /> 
}

export default RouteComponent;
