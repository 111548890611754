import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { Checkbox, Progress } from 'antd';

import Container from '../../../../components/container';

import Button from '../../../../components/button';
import Heading from '../../../../components/heading';
import Text from '../../../../components/text';
import Footer from '../../../../components/footer';

export const RegisterWrapper = styled.div`
  height: calc(100vh - 10.3em);
  display: flex;
  align-items: center;
`;

export const ContainerStyled = styled(Container)``;

export const Content = styled.div`
  width: 48vw;
  max-width: 504px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    width: 100%;
    max-width: unset;
  `}
`;

export const FormContainer = styled.div`
  padding-top: 20px;
  position: relative;

  ${({ theme }) => theme.media.md`
    padding-top: 60px;
  `}
`;

export const GoBackButton = styled(Button)`
  padding-bottom: 24px;
`;

export const FormContent = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  height: calc(100vh - 12.8rem);

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-moz-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.xxl`
    
  `}
  ${({ theme }) => theme.media.xl`

  `}
  ${({ theme }) => theme.media.lg`

  `}
  ${({ theme }) => theme.media.md`
    height: calc(100vh - 16rem);
  `}
`;

export const HeaderRegister = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 30px;
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CheckboxStyled = styled(Checkbox)<{ hasChecked?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: ${props =>
    props.hasChecked === true
      ? css`
    1px solid ${({ theme }) => theme.colors.blue.light}
    `
      : css`
      none;
    `};
  padding: ${props =>
    props.hasChecked === true
      ? css`
    15px 24px;
    `
      : css`
      16px 24px;
    `};

  span {
    top: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.blue.light};
  }
`;

export const ButtonStyled = styled(Button)`
  margin-top: 8px;
  height: 48px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const ConfirmContainer = styled.div`
  height: calc(100vh - 20rem);
`;

export const ProgressStyled = styled(Progress)`
  span {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const FooterStyled = styled(Footer)<{ isMobile?: boolean }>`
  margin-top: 48px;
  display: none;
  div {
    padding: ${({ isMobile }) => (isMobile ? '0' : '16px 0')};
    margin: 0;
  }

  ${({ theme }) => theme.media.md`
    display: block;
  `}
`;
