import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import NFListLayout from './NfListLayout';

import { ExternalPaths } from '../../../../constants/routes';

import OrderService from '../../../../services/OrderService';

import { useAuth } from '../../../../hooks/auth';
import { useNavigationInfos } from '../../../../hooks/navigationInfos';

export interface IGetParams {
  skip: number;
  take: number;
  filter?: string;
}

export interface IInvoice {
  DataHora_TMS: string;
  DtPrevisao_TMS: string;
  Emitente_TMS: string;
  NumPedido_TMS: string;
  isNotas: string;
  status_codigo: number;
}

const NFList: React.FC = () => {
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [allOrders, setAllOrders] = useState<IInvoice[]>([]);
  const [searchParams, setSearchParams] = useState<string | undefined>();
  const [skipPeirPage, setSkipPeirPage] = useState(0);
  const [countOrders, setCountOrders] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [takePeirPage] = useState(12);

  const [loading, setLoading] = useState(true);

  const { nf, setNf } = useNavigationInfos();
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const requestGetCTEs = async (filter?: string) => {
    const orderService = new OrderService();

    if (skipPeirPage === 0 || filter) {
      setLoading(true);
    }

    let getParams: IGetParams = {
      skip: 0,
      take: 12,
    };

    try {
      if (filter) {
        getParams = {
          skip: 0,
          take: takePeirPage,
          filter: filter,
        }
      } else if (searchParams) {
        getParams = {
          skip: skipPeirPage,
          take: takePeirPage,
          filter: searchParams,
        }
      } else {
        getParams = {
          skip: skipPeirPage,
          take: takePeirPage,
        }
      }

      const response = await orderService.getHistory(getParams);
      
      if (filter) {
        setSearchParams(filter);
        setAllOrders([...response.data.list]);
        setSkipPeirPage(0 + 12);
      } else {
        setAllOrders([...allOrders, ...response.data.list]);
        setSkipPeirPage(skipPeirPage + 12);
      }

      setCountOrders(response.data.count);
      setLoading(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          logOut();
          navigate(ExternalPaths.TIMEOUT);
        } else if (err.response?.status === 500) {
          navigate(ExternalPaths.API_ERROR);
        } else {
          navigate(ExternalPaths.API_ERROR);
        }
      } else {
        navigate(ExternalPaths.API_ERROR);
      } 
    }
  }

  useEffect(() => {
    if (nf) {
      requestGetCTEs(nf);
      setNf(null);
    } else {
      requestGetCTEs();
    }
  }, []);

  useEffect(() => {
    if (allOrders.length === 0)
      requestGetCTEs();
  }, [allOrders]);

  const handleOpen = () => {
    if (showMobileSearch === false) {
      setShowMobileSearch(true);
    } else if (showMobileSearch === true) {
      setShowMobileSearch(false);
    }
  };

  const handleClearSearch = () => {
    setLoading(true);

    setSearchParams(undefined);
    setShowResult(false);
    setSkipPeirPage(0);
    setCountOrders(0);
    setAllOrders([]);
  };

  return (
    <NFListLayout
      loading={loading}
      allOrders={allOrders}
      showResult={showResult}
      countOrders={countOrders}
      searchParams={searchParams}
      showMobileSearch={showMobileSearch}
      handleOpen={handleOpen}
      handleGetCTEs={requestGetCTEs}
      handleClearSearch={handleClearSearch}
    />
  );
}

export default NFList;