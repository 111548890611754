import styled from 'styled-components';

import { Link } from 'react-router-dom';


import EmptyMapImage from '../../../../components/imgs/bg-map.png';
import theme from '../../../../constants/theme';

export const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(163, 214, 255, 0.1),
      rgba(163, 214, 255, 0.1)
    ),
    #ffffff;

  position: relative;

  ${({ theme }) => theme.media.md`
    padding: 16px 0 32px;
  `}

  @media screen and (max-width: 809px) {
    width: 100%;
    padding: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  min-width: 648px;
  max-width: 920px;

  padding: 40px 56px 0px 204px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 1440px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (max-width: 575px) {
    min-width: 0;
  }
`;

export const DetailWrapper = styled.div`
  width: 660px;

  @media screen and (max-width: 960px) {
    width: 100%;
    padding-left: 0;
  }
`;

export const MapWrapper = styled.div`
  left: -32px;

  width: 100vw;
  position: relative;

  @media (min-width: 960px) {
    display: none;
  }
`;

export const EmptyMap = styled.div`
  background-image: url(${EmptyMapImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  span + span {
    margin-top: 4px;
  }
`;

export const EmptyMapText = styled.span`
  background-color: ${theme.colors.white};
  color: ${theme.colors.blue.darkness};

  padding: 0 6px;
  max-width: 355px;
  text-align: center;

  border-radius: 4px;

  
`;

export const MapLocationMobile = styled.div<{ scroll: number }>`
  height: 288px;
`;

export const MapLocation = styled.div<{ scroll: number }>`
  width: 100%;
  height: calc(100vh - ${({ scroll }) => `${scroll}`}px);

  position: sticky;

  top: 0;
  right: 0;

  @media (max-width: 1024px) {
    width: 452px;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const MainContent = styled.div`
  width: 648px;

  @media (max-width: 912px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 1.5rem 0;

  padding: 1rem;
`;

export const InvoiceNumber = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${theme.colors.blue.darkness};

  padding-left: 10px;

  @media screen and (max-width: 960px) {
    padding-left: 0;
  }
`;

export const ContentSubtitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${theme.colors.blue.darkness};
`;

export const ContentContact = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
`;

export const ContactDescription = styled.span`
  font-weight: 300;
  font-size: 12px;
  color: #${theme.colors.grey.darkness_mid};
`;

export const ContactPhone = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #${theme.colors.grey.darkness_mid};

  margin-left: 0.25rem;
`;

export const ReceiptTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.blue.darkness};
`;

export const CarouselWrapper = styled.div`
  padding: 1.25rem 0;

  @media (max-width: 425px) {
    padding: 1.25rem 0;
  }

  .carousel-status {
    display: none;
  }
`;

export const LoadingWrapper = styled.div`
  width: 660px;
  height: 500px;

  margin: 1.5rem 0;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 912px) {
    width: 100%;
  }
`;

export const CarouselOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  background-color: ${theme.colors.blue.dark};

  display: flex;
  align-items: center;
  justify-content: center;

  .carousel-status {
    display: none;
  }
`;

export const CarouselOverlayWrapper = styled.div`
  width: 40%;

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const OverlayHeader = styled.div`
  width: 100%;
  position: fixed;

  top: 0;
  z-index: 2;

  display: flex;
  justify-content: space-between;

  padding: 1.2rem;
`;

export const OverlayButton = styled.button`
  color: white;
  background: none;

  font-size: 16px;
  font-weight: 600;
`;

export const StyledLink = styled(Link)`
  width: 660px;
  font-weight: 500;
  color: ${theme.colors.blue.midle};

  padding-left: 10px;

  span + span {
    margin-left: 5px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    padding-left: 0;
  }
`;
