function CreatedDone({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#A3D6FF"/>
      <path d="M18.7258 16.0682L23.5659 16.0681C23.8056 16.0681 23.9999 15.8738 23.9999 15.634C23.9999 15.3943 23.8056 15.2 23.5659 15.2L18.7258 15.2001C18.4861 15.2001 18.2918 15.3945 18.2918 15.6342C18.2918 15.8739 18.4861 16.0682 18.7258 16.0682Z" fill="#161F3B"/>
      <path d="M16.434 16.0682H16.7039C16.9436 16.0682 17.138 15.8739 17.138 15.6342C17.138 15.3945 16.9436 15.2001 16.7039 15.2001H16.434C16.1942 15.2001 15.9999 15.3945 15.9999 15.6342C15.9999 15.8739 16.1942 16.0682 16.434 16.0682Z" fill="#161F3B"/>
      <path d="M18.7258 20.3006L23.5659 20.3005C23.8056 20.3005 23.9999 20.1061 23.9999 19.8664C23.9999 19.6267 23.8056 19.4324 23.5659 19.4324L18.7258 19.4325C18.4861 19.4325 18.2918 19.6268 18.2918 19.8665C18.2918 20.1063 18.4861 20.3006 18.7258 20.3006Z" fill="#161F3B"/>
      <path d="M16.434 20.3006H16.7039C16.9436 20.3006 17.138 20.1063 17.138 19.8666C17.138 19.6268 16.9436 19.4325 16.7039 19.4325H16.434C16.1942 19.4325 15.9999 19.6268 15.9999 19.8666C15.9999 20.1063 16.1942 20.3006 16.434 20.3006Z" fill="#161F3B"/>
      <path d="M18.7258 22.4171L23.5659 22.4169C23.8056 22.4169 23.9999 22.2226 23.9999 21.9829C23.9999 21.7432 23.8056 21.5488 23.5659 21.5488L18.7258 21.549C18.4861 21.549 18.2918 21.7433 18.2918 21.983C18.2918 22.2227 18.4861 22.4171 18.7258 22.4171Z" fill="#161F3B"/>
      <path d="M16.434 22.4171H16.7039C16.9436 22.4171 17.138 22.2227 17.138 21.983C17.138 21.7433 16.9436 21.549 16.7039 21.549H16.434C16.1942 21.549 15.9999 21.7433 15.9999 21.983C15.9999 22.2227 16.1942 22.4171 16.434 22.4171Z" fill="#161F3B"/>
      <path d="M21.2807 24.5334L18.7258 24.5335C18.4861 24.5335 18.2918 24.3392 18.2918 24.0995C18.2918 23.8598 18.4861 23.6654 18.7258 23.6654L21.2807 23.6653C21.5204 23.6653 21.7148 23.8596 21.7148 24.0994C21.7148 24.3391 21.5204 24.5334 21.2807 24.5334Z" fill="#161F3B"/>
      <path d="M16.7039 24.5336H16.434C16.1942 24.5336 15.9999 24.3392 15.9999 24.0995C15.9999 23.8598 16.1942 23.6654 16.434 23.6654H16.7039C16.9436 23.6654 17.138 23.8598 17.138 24.0995C17.138 24.3392 16.9436 24.5336 16.7039 24.5336Z" fill="#161F3B"/>
      <path d="M23.5659 18.1846L18.7258 18.1847C18.4861 18.1847 18.2918 17.9904 18.2918 17.7507C18.2918 17.5109 18.4861 17.3166 18.7258 17.3166L23.5659 17.3165C23.8056 17.3165 23.9999 17.5108 23.9999 17.7505C23.9999 17.9902 23.8056 18.1846 23.5659 18.1846Z" fill="#161F3B"/>
      <path d="M16.7039 18.1847H16.434C16.1942 18.1847 15.9999 17.9904 15.9999 17.7507C15.9999 17.511 16.1942 17.3166 16.434 17.3166H16.7039C16.9436 17.3166 17.138 17.511 17.138 17.7507C17.138 17.9904 16.9436 18.1847 16.7039 18.1847Z" fill="#161F3B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5062 27.1333C16.6534 27.1333 16.6666 27.1333 16.7728 27.1333H19.4322C19.4791 27.1333 19.6266 27.1333 19.6889 27.1333H25.0666C25.4716 27.1333 25.7999 26.805 25.7999 26.4V13.6C25.7999 13.195 25.4716 12.8667 25.0666 12.8667H14.9333C14.5282 12.8667 14.1999 13.195 14.1999 13.6V26.4C14.1999 26.805 14.5282 27.1333 14.9333 27.1333H16.5062ZM16.5062 28C16.2433 28 16.1097 28 15.9062 28H14.9333C14.0496 28 13.3333 27.2837 13.3333 26.4V13.6C13.3333 12.7163 14.0496 12 14.9333 12H25.0666C25.9502 12 26.6666 12.7163 26.6666 13.6V26.4C26.6666 27.2837 25.9502 28 25.0666 28H19.9062H16.5062Z" fill="#161F3B"/>
    </svg>
  );
}

export default CreatedDone;
