import styled, { css } from 'styled-components';

interface BGProps {
  bgOpacity?: boolean;
  maxHeight?: any;
}

export const BgImage = styled.div<BGProps>`
  width: 100%;
  background-image: url('./bg@832x900.png');
  background-repeat: no-repeat;
  transition: ease 0.1s;
  background-position: 56vw;
  background-size: 70%;

  ${({ bgOpacity }) =>
    bgOpacity &&
    css`
      background-position-x: 40vw;
      background-size: 70%;
    `}

  ${({ theme }) => theme.media.xxl`
    background-position: 58vw;
    background-size: 70%;
    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background-position: 42vw;
        background-size: 70%;
      `}
  `}

  ${({ theme, maxHeight }) => theme.media.xl`
    background-position: ${maxHeight > 760 ? '42vw' : '60vw'};
    background-size: ${maxHeight > 760 ? 'cover' : '70%'};
    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background-position: 30vw;
        background-size: 100%;
      `}
  `}

  ${({ theme, maxHeight }) => theme.media.x`
    background-position: ${maxHeight > 760 ? '42vw' : '62vw'};
    background-size: ${maxHeight > 760 ? '100%' : '70%'};
    
    ${({ bgOpacity, maxHeight }: BGProps) =>
      bgOpacity &&
      css`
        background-position: 42vw;
        background-size: ${maxHeight > 760 ? '100%' : '70%'};
      `}
  `}

  ${({ theme }) => theme.media.lg`
    background-position: 52vw;
    background-size: cover;
    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background-position-x: 34vw;
        background-size: cover;
      `}
  `}

  ${({ theme }) => theme.media.md`
    background-image: url('./bg@560x420.png');
    background-position: 0vw -26vw;
    background-size: cover;
    height: 56vh;

    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background-position: 0vw -12vw;
        background-size: cover;
        height: 50vh;
      `}
  `}

  ${({ theme }) => theme.media.sm`
    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background-position: 0vw -1vw;
      `}
  `}
`;

export const MaskImage = styled.div<BGProps>`
  background: linear-gradient(
    201.01deg,
    rgb(0 131 217 / 59%) 33.5%,
    rgba(30, 52, 101, 0.75) 100%
  );

  ${({ bgOpacity }) =>
    bgOpacity &&
    css`
      background: linear-gradient(
        292deg,
        rgb(0 131 217 / 12%) 33.5%,
        rgb(0 131 217 / 68%) 52%
      );
    `}

  ${({ theme }) => theme.media.md`
    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background: linear-gradient(
          180deg,
          rgb(0 131 217 / 9%) 18.5%,
          rgb(0 131 217 / 98%) 60%
        );
      `}
   `}
`;

export const BgGradient = styled.div<BGProps>`
  transition: ease 0.1s;
  background: linear-gradient(
    106deg,
    #161f3b 44%,
    #1c609c 76%,
    rgba(2, 119, 185, 0) 76%,
    rgba(38, 216, 255, 0) 100%
  );

  ${({ bgOpacity }) =>
    bgOpacity &&
    css`
      background: linear-gradient(
        106deg,
        #161f3b 20%,
        #1c609c 58%,
        rgba(2, 119, 185, 0) 58%,
        rgba(38, 216, 255, 0) 100%
      );
    `}

  ${({ theme }) => theme.media.md`
    background: linear-gradient(
      2deg,
      #161f3b 44%,
      #1c609c 84%,
      rgba(2, 134, 132, 0) 76%,
      rgba(38, 216, 255, 0) 100%
    );

    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background: linear-gradient(
          2deg,
          #161f3b 4%,
          #1c609c 62%,
          rgba(2, 134, 132, 0) 62.2%,
          rgba(38, 216, 255, 0) 100%
        );
      `}
  `}
`;

export const BgCut = styled.div<BGProps>`
  transition: ease 0.1s;

  background: linear-gradient(
    106deg,
    #ffffff14 76%,
    #1077b900 42%,
    rgba(16, 119, 185, 0) 100%,
    rgba(38, 218, 255, 0) 96%
  );

  ${({ bgOpacity }) =>
    bgOpacity &&
    css`
      background: linear-gradient(
        106deg,
        #ffffff14 58%,
        #1077b900 58%,
        rgba(16, 119, 185, 0) 100%,
        rgba(38, 218, 255, 0) 96%
      );
    `}

  ${({ theme }) => theme.media.md`
    background: linear-gradient(
      2deg,
      #ffffff14 84%,
      #1077b900 42%,
      rgba(16, 119, 185, 0) 100%,
      rgba(38, 218, 255, 0) 96%
    );

    ${({ bgOpacity }: BGProps) =>
      bgOpacity &&
      css`
        background: linear-gradient(
          2deg,
          #ffffff14 61.1%,
          #1077b926 62.2%,
          rgba(16, 119, 185, 0) 62%,
          rgb(38 216 255 / 0%) 100%
        );
      `}
   `}
`;
