export const STEP_TMS = {
  ViagemDeEntrega: 0,
  Entregue: 1,
  EntregaDeTransferencia: 2,
  AguardandoEmbarque: 3,
  EntregaAgendada: 4,
  EntregaParcial: 5,
  RecusaTotal: 6,
  EmSeparacao: 7,
  EntregaTransportadora: 8,
  BaixadoSemEntrega: 10,
  EmTransito: 11,
  SemPosicao: 12,
  EmOcorrencia: 13,
  SolicitadoAgenda: 14,
  Agendada: 15,
  EntregaComAtraso: 16,
  Cancelado: 97,
  AguardandoLancamentoNf: 98,
  ServicoNaoContratado: 99
}

export const STEP_COLUMN = {
  CREATED: 0,
  PREPARED: 1,
  IN_TRANSIT: 2,
  ON_THE_WAY: 3,
  DELIVERED: 4,
  PARTIALLY_DELIVERED: 5,
  DENIED_DELIVERED: 6,
  CANCELED_DELIVERED: 7,
  IGNORE: 8,
};
