import styled from 'styled-components';

import { List } from 'antd';
import Text from '../../../../../components/text';
import { Truck } from '../../../../../components/icons';
import Button from '../../../../../components/button';
import { RightOutlined } from '@ant-design/icons';
import { statusBg, statusInner } from './tags';

const { Item } = List;

export const ListItem = styled(Item)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 78px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey.mid};
  padding: 16px 24px;
  display: flex;
  transition: ease 0.2s;
  cursor: pointer;
  gap: 24px;

  ${({ theme }) => theme.media.md`
    height: auto;
    padding: 16px;
    flex-direction: row;
    gap: unset;
    align-items: start;

  `}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    width: 70%;
    gap: 1.5em;
  }

  &:last-child {
    width: 260px;
    gap: 14px;

    div:nth-child(2) {
      width: auto;
    }
  }

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: flex-start;
    gap: unset;
  
    &:first-child {
      width: 68%;
      gap: 8px;
      
    }

    &:last-child {
      width: unset;
      gap: 9px;
    }
  `}

  ${({ theme }) => theme.media.sm`
    &:first-child {
      width: 62%;
    }
  `}

  ${({ theme }) => theme.media.xs`
    &:first-child {
      width: 50%;
    }
  `}
`;

export const ChildItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  &:nth-child(1) {
    width: 100px;
  }
  &:nth-child(2) {
    width: 6vw;
  }
  &:nth-child(3) {
    width: 8vw;
  }

  ${({ theme }) => theme.media.xxl`
    &:nth-child(1) {
      width: 290px;
    }
    &:nth-child(2) {
      width: 20vw;

      div{
        max-width: 210px;
      }
    }
    &:nth-child(3) {
      width: 26.8vw;
      min-width: 12vw;
      div{
        max-width: unset;
      }
    }

  `}

  ${({ theme }) => theme.media.xl`
    &:nth-child(1) {
      width: 133px;
    }
    &:nth-child(2) {
      width: 22vw;
    }
    &:nth-child(3) {
      width: 26vw;
    }

  `}

  ${({ theme }) => theme.media.lg`
    &:nth-child(1) {
      
    }
    &:nth-child(2) {
      width: 16vw;
    }
    &:nth-child(3) {
      width: 22vw;
    }
  `}

  ${({ theme }) => theme.media.md`
    &:nth-child(2) {
      width: 100%;
      min-width: unset;
    }
    &:nth-child(3) {
      width: 100%;
      min-width: unset;
    }
    
    div{
      max-width: unset;
    }
  `}
`;

export const NFNumber = styled(Text)`
  color: ${({ theme }) => theme.colors.blue.midle};
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.blue.darkness};
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const Description = styled.div`
  max-width: 232px;
  color: ${({ theme }) => theme.colors.grey.midle};
  line-height: 22px;

  font-size: 14px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme }) => theme.media.md`
    max-width: 130px;
  `}
`;

export const StatusTag = styled.div<{ status: number }>`
  width: 100px;
  height: 54px;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${({ status }) => statusBg(status)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  ${({ theme }) => theme.media.md`
    height: 44px;
  `}
`;

export const StatusText = styled(Text)<{ status: number }>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({ status }) => statusInner(status)};
  line-height: 0;
`;

export const TruckStyled = styled(Truck)<{ status: number }>`
  display: ${({ status }) => (status === 3 ? 'block' : 'none')};
  fill: #ffffff;
  width: 22px;
  height: 16px;

  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const ButtonStyled = styled(Button)``;

export const ArrowStyled = styled(RightOutlined)`
  color: ${({ theme }) => theme.colors.blue.midle};
  svg {
    width: 16.75px;
    height: 20.3px;
  }

  ${({ theme }) => theme.media.md`
    align-self: end;
    margin-top: 20px;
  `}
`;
