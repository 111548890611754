import { useEffect, useState } from 'react';

let countdownTImeout: NodeJS.Timeout;

export default function useCountDown() {
  const [time, setTime] = useState(15 * 60);
  const [isActive, setIsActive] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  function resetCountdow() {
    clearTimeout(countdownTImeout);
    setIsActive(false);
    setHasFinished(false);
    setTime(15 * 60);
  }

  useEffect(() => {
    setIsActive(true);
    if (isActive && time > 0) {
      countdownTImeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (isActive && time === 0) {
      setHasFinished(true);
      setIsActive(false);
    }
  }, [isActive, time]);

  return {
    time,
    minutes,
    seconds,
    resetCountdow,
    hasFinished,
  };
}
