function DeliveredActive({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#0DA6F2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1808 12.4917L17.9859 9.35516C17.9099 9.32611 17.8264 9.32611 17.7505 9.35516L9.55553 12.4919C9.52869 12.5022 9.50288 12.5159 9.47888 12.5332C9.38763 12.5992 9.33325 12.7073 9.33325 12.8226V22.914C9.33325 23.0617 9.42197 23.1938 9.55556 23.2449L17.7504 26.3814C17.7583 26.3844 17.7662 26.3871 17.7741 26.3895C18.7347 28.8912 21.1598 30.6669 23.9997 30.6669C27.6815 30.6669 30.6661 27.6822 30.6661 24.0005C30.6661 21.166 28.8972 18.7448 26.4031 17.7804V12.8226C26.4031 12.6748 26.3144 12.5428 26.1808 12.4917Z" fill="#A3D6FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1808 12.4917L17.9859 9.35516C17.9099 9.32611 17.8264 9.32611 17.7505 9.35516L9.55553 12.4919C9.52869 12.5022 9.50288 12.5159 9.47888 12.5332C9.38763 12.5992 9.33325 12.7073 9.33325 12.8226V22.914C9.33325 23.0617 9.42197 23.1938 9.55556 23.2449L17.7504 26.3814C17.7583 26.3844 17.7662 26.3871 17.7741 26.3895C18.7347 28.8912 21.1598 30.6669 23.9997 30.6669C27.6815 30.6669 30.6661 27.6822 30.6661 24.0005C30.6661 21.166 28.8972 18.7448 26.4031 17.7804V12.8226C26.4031 12.6748 26.3144 12.5428 26.1808 12.4917ZM17.3333 24.0005C17.3333 23.4466 17.4008 22.9086 17.5281 22.3941V16.2049L15.8959 15.5802V19.5936C15.8959 19.709 15.8415 19.817 15.7503 19.883C15.6925 19.9248 15.6244 19.9464 15.5558 19.9464C15.5162 19.9464 15.4764 19.9392 15.4381 19.9246L11.7658 18.519C11.6322 18.4678 11.5435 18.3357 11.5435 18.188V13.9144L10.0133 13.3287V22.6682L17.5114 25.538C17.3949 25.0444 17.3333 24.5297 17.3333 24.0005ZM23.9997 17.334C21.5201 17.334 19.3567 18.6878 18.2082 20.6966V16.2049L25.723 13.3287V17.5589C25.1733 17.4122 24.5956 17.334 23.9997 17.334ZM17.8682 15.5831L16.5346 15.0727L19.346 13.9966C19.4796 13.9454 19.5683 13.8134 19.5683 13.6657C19.5683 13.5179 19.4796 13.3859 19.346 13.3348L15.6736 11.9292C15.5976 11.9001 15.5142 11.9001 15.4382 11.9292L11.8798 13.2912L10.6556 12.8226L17.8682 10.0621L25.0807 12.8226L17.8682 15.5831Z" fill="#263465"/>
      <path d="M27.5269 22.494C27.7239 22.3245 27.7462 22.0273 27.5766 21.8303C27.4071 21.6333 27.1099 21.611 26.9129 21.7805L22.5773 25.5111L21.1715 23.7506C21.0092 23.5476 20.713 23.5147 20.51 23.677C20.307 23.8393 20.274 24.1355 20.4363 24.3385L21.9945 26.2895C22.2442 26.6018 22.7032 26.6446 23.0063 26.3838L27.5269 22.494Z" fill="#A3D6FF"/>
      <path d="M12.6667 15.3508C12.7057 15.2412 12.823 15.1851 12.9288 15.2255L14.6055 15.8673C14.7112 15.9077 14.7653 16.0294 14.7263 16.1391C14.6958 16.2246 14.6179 16.2775 14.5348 16.2775C14.5114 16.2775 14.4875 16.2733 14.4642 16.2644L12.7875 15.6226C12.6818 15.5822 12.6277 15.4605 12.6667 15.3508Z" fill="#A3D6FF"/>
      <path d="M12.6667 16.3345C12.7057 16.2248 12.823 16.1688 12.9288 16.2092L14.6055 16.851C14.7112 16.8914 14.7653 17.0131 14.7263 17.1228C14.6958 17.2083 14.6179 17.2612 14.5348 17.2612C14.5114 17.2612 14.4875 17.257 14.4642 17.248L12.7875 16.6063C12.6818 16.5658 12.6277 16.4441 12.6667 16.3345Z" fill="#A3D6FF"/>
      <path d="M12.9288 17.1929C12.823 17.1525 12.7057 17.2085 12.6667 17.3182C12.6277 17.4278 12.6818 17.5495 12.7875 17.59L14.4642 18.2317C14.4875 18.2406 14.5114 18.2449 14.5348 18.2449C14.6179 18.2449 14.6958 18.1919 14.7263 18.1065C14.7653 17.9968 14.7112 17.8751 14.6055 17.8346L12.9288 17.1929Z" fill="#A3D6FF"/>
    </svg>
  );
}

export default DeliveredActive;
