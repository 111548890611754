import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ContainerProps {
  children: ReactNode;
}

export const ContainerStyled = styled.div`
  ${({ theme }) => theme.setContainer()};
`;

const Container: FC<ContainerProps> = ({ children }) => {
  return <ContainerStyled>{children}</ContainerStyled>;
};

export default Container;
