function DestinationMap({ ...props }) {
  return (
    <svg viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 11C2 8.04138 3.01005 5.55303 4.7274 3.80825C6.44238 2.06589 8.92648 1 12 1C15.0735 1 17.5576 2.06589 19.2726 3.80825C20.99 5.55303 22 8.04138 22 11C22 13.2505 21.0697 16.0859 19.7089 18.9268C18.3604 21.7419 16.6451 24.4485 15.1964 26.4049C14.7889 26.9552 14.3071 27.58 13.8185 28.2139C13.616 28.4765 13.4123 28.7407 13.2123 29.0017C12.7735 29.5743 12.3447 30.1412 12.0002 30.627C11.4596 29.8626 10.6929 28.868 9.96754 27.9269C9.54715 27.3816 9.14062 26.8542 8.8 26.4C7.33521 24.4469 5.62095 21.7424 4.27745 18.928C2.92207 16.0887 2 13.2522 2 11Z" fill="white" stroke="#263465" stroke-width="2"/>
      <g clip-path="url(#clip0_421_19804)">
        <path d="M12 14.7269L15.8625 16.9718L14.8375 12.7408L18.25 9.89403L13.7563 9.5269L12 5.53662L10.2437 9.5269L5.75 9.89403L9.1625 12.7408L8.1375 16.9718L12 14.7269Z" fill="#263465"/>
      </g>
      <defs>
        <clipPath id="clip0_421_19804">
          <rect width="15" height="14.4444" fill="white" transform="translate(4.5 4.33301)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DestinationMap;