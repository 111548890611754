import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Formik, FormikErrors } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ArrowLeftOutlined } from '@ant-design/icons';

import BoxAlert from '../../../../components/boxAlert';
import Inputfield from '../../../../components/inputField';

import SessionService from '../../../../services/SessionService';

import { useNavigationInfos } from '../../../../hooks/navigationInfos';

import Yup from '../../../../utils/yup';
import { validateEmail, validatePhone } from '../../../../utils/validation';

import {
  Content,
  FormStyled,
  FormContent,
  Description,
  ButtonStyled,
  GoBackButton,
  HeaderConsult,
  HeadingStyled,
  FormContainer,
  ConsultWrapper,
  ContainerStyled,
} from './styles';
import Layout from '../../../../components/layout';

const FieldNames = {
  NF_DELIVERY: 'nfNumber',
  EMAIL_OR_CELLPHONE: 'emailOrPhone',
};

const Consult = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [reCaptcha, setReCaptcha] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const reCaptchaRef = useRef<any>();

  const { setResetToken } = useNavigationInfos();
  const navigate = useNavigate();

  const handleSubmit = async (values: { [x: string]: string }, setErrors: (errors: FormikErrors<{[x: string]: string;}>) => void) => {
    setIsLoading(true);
    setShowError(false);
    setErrorMessage(undefined);

    const sessionService = new SessionService();

    if (reCaptcha) {
      try {
        const response = await sessionService.TrackInvoiceSession({contact: values[FieldNames.EMAIL_OR_CELLPHONE], nf: values[FieldNames.NF_DELIVERY], captchaToken: reCaptcha});
        
        if (response) {
          setResetToken(response.data.resetToken);
        }

        navigate('/confirm-token');
      } catch(err) {
        setIsLoading(false);
        reCaptchaRef.current.reset();
        setReCaptcha(undefined);

        if (axios.isAxiosError(err)) {
          if (err.response?.status === 400) {
            setShowError(true);
          
            setErrors({ 
              [FieldNames.EMAIL_OR_CELLPHONE]: ' ',
              [FieldNames.NF_DELIVERY]: ' ',
            });

            setErrorMessage('Número da nota e/ou e-mail inválidos.');
          } else if (err.response?.status === 401) {
            setShowError(true);
            setErrorMessage('Recaptcha inválido.');
          } else {
            setShowError(true);
            setErrorMessage('Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.');
          }
        } else {
          setShowError(true);
          setErrorMessage('Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.');
        }
      }
    }
  };

  return (
    <Layout mode={"external"}>
      <ConsultWrapper>
        <ContainerStyled>
          <Content>
            <FormContainer>
              <GoBackButton
                mode="action"
                leftElement={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              >
                voltar
              </GoBackButton>
              <FormContent className="scroller">
                <HeaderConsult>
                  <HeadingStyled type="h2">Rastrear nota</HeadingStyled>
                  <Description>
                    <span>
                      Para rastrear uma nota você precisa ter um cadastro ativo.
                      Se você não recebeu um e-mail de ativação, contate o seu
                      fornecedor.
                    </span>
                  </Description>
                  {showError ? 
                    !errorMessage ? (
                      <BoxAlert label="Dados em conflito. Por favor, verifique os campos assinalados." />
                    ) : (
                      <BoxAlert label={errorMessage} />
                    ) : null
                  }
                </HeaderConsult>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                >
                  {formik => {
                    const { values, isValid, dirty } = formik;

                    return (
                      <FormStyled>
                        <Inputfield
                          name={FieldNames.NF_DELIVERY}
                          label="NF de entrega (sem o dígito)*"
                          type="text"
                          maxLength={9}
                          placeholder="Digite o nº de nota para buscar"
                        />

                        <Inputfield
                          name={FieldNames.EMAIL_OR_CELLPHONE}
                          type="text"
                          label="E-mail / Celular*"
                          placeholder="Digite o e-mail ou celular"
                          mask={
                            values.emailOrPhone.length > 1
                              ? /^[0-9 ()-]*$/.test(values.emailOrPhone)
                                ? '(99) 99999-9999'
                                : /^[0-9A-Za-z@.]*$/
                              : /^[0-9A-Za-z@.]*$/
                          }
                          maskChar={null}
                        />

                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                          onChange={(value: any) => {
                            setReCaptcha(`${value}`)
                          }}
                        />

                        <ButtonStyled
                          mode="default"
                          htmlType="submit"
                          isLoading={isLoading}
                          disabled={!(dirty && isValid && reCaptcha)}
                        >
                          Prosseguir
                        </ButtonStyled>
                      </FormStyled>
                    );
                  }}
                </Formik>
              </FormContent>
            </FormContainer>
          </Content>
        </ContainerStyled>
      </ConsultWrapper>
    </Layout>
  );
};

export default Consult;

const initialValues = {
  [FieldNames.NF_DELIVERY]: '',
  [FieldNames.EMAIL_OR_CELLPHONE]: '',
};

const validationSchema = Yup.object().shape({
  [FieldNames.NF_DELIVERY]: Yup.number()
    .typeError('Insira apenas números')
    .required('Campo obrigatório'),
  [FieldNames.EMAIL_OR_CELLPHONE]: Yup.string()
    .required('Campo obrigatório')
    .test(
      'validate-phone-or-email',
      'Insira um e-mail/telefone válido',
      function (value) {
        let isValidPhone = validatePhone(value);
        let isValidEmail = validateEmail(value);

        if (!isValidEmail && !isValidPhone) return false;
        return true;
      },
    ),
});
