import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Formik, FormikErrors } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ArrowLeftOutlined } from '@ant-design/icons';

import SessionService from '../../../../services/SessionService';

import { useAuth } from '../../../../hooks/auth';

import BoxAlert from '../../../../components/boxAlert';
import Inputfield from '../../../../components/inputField';

import Yup from '../../../../utils/yup';
import { validateCnpj, validateCpf, validateConfirmationCode, validatePhone } from '../../../../utils/validation';

import {
  ButtonStyled,
  Content,
  FormContent,
  Description,
  FormStyled,
  HeadingStyled,
  HeaderRegister,
  CheckboxStyled,
  GoBackButton,
  ProgressStyled,
  FormContainer,
  FooterStyled,
  ConfirmContainer,
  RegisterWrapper,
  ContainerStyled,
} from './styles';
import Layout from '../../../../components/layout';

const FieldNames = {
  CNPJ_OR_CPF: 'cnpjOrCpf',
  CELL_PHONE: 'cellPhone',
  MAIL_CODE: 'mailCode',
};

const RegisterForm = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [checkTerms, setCheckTerms] = useState(false);
  const [hasConfirm, setHasConfirm] = useState(false);
  const [reCaptcha, setReCaptcha] = useState<string>();
  const [percent, setPercent] = useState(0);
  // const [scroll, setScroll] = useState(false);
  const [showError, setShowError] = useState(false);
  const reCaptchaRef = useRef<any>();
  
  const { logIn, logUser } = useAuth();
  const navigate = useNavigate();

  const configApi = {
    onUploadProgress: function(progressEvent: any) {
      setPercent(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
    }
  };

  // useEffect(() => {
  //   const scroller = document.querySelector('.scroller');

  //   scroller?.addEventListener('scroll', () => {
  //     if (scroller.scrollTop >= 470) {
  //       setScroll(true);
  //     } else {
  //       setScroll(false);
  //     }
  //   });
  // }, []);

  const handleSubmit = async (values: { [x: string]: string }, setErrors: (errors: FormikErrors<{[x: string]: string;}>) => void) => {
    setHasConfirm(true);
    setShowError(false);
    setErrorMessage(undefined);

    const sessionService = new SessionService();

    if (reCaptcha) {
      try {
        const payload = values[FieldNames.CELL_PHONE] === '' ? {
          document: values[FieldNames.CNPJ_OR_CPF], 
          verification_code: values[FieldNames.MAIL_CODE], 
          accept_terms: true,
          captchaToken: reCaptcha,
        } : {
          document: values[FieldNames.CNPJ_OR_CPF], 
          phone: values[FieldNames.CELL_PHONE], 
          verification_code: values[FieldNames.MAIL_CODE], 
          accept_terms: true,
          captchaToken: reCaptcha,
        }

        const response = await sessionService.ConfirmRegister(payload, configApi);
        
        logIn(response.data.token);
        logUser({id: response.data.id, name: response.data.name, customerId: response.data.customerId, hasTrackingMap: response.data.hasTrackingMap});

        navigate('/');
      } catch(err) {
        setHasConfirm(false);
        reCaptchaRef.current.reset();
        setReCaptcha(undefined);

        if (axios.isAxiosError(err)) {
          if (err.response?.status === 400) {
            setShowError(true);

            setErrors({ 
              [FieldNames.CNPJ_OR_CPF]: ' ',
              [FieldNames.CELL_PHONE]: ' ',
              [FieldNames.MAIL_CODE]: ' ',
            });

          } else if (err.response?.status === 401) {
            setShowError(true);

            setErrorMessage('Recaptcha inválido.');
          } else {
            setShowError(true);

            setErrorMessage('Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.');
          }
        } else {
          setShowError(true);
          
          setErrorMessage('Ocorreu um problema em nosso sistema. Aguarde um pouco e tente novamente.');
        }
      }
    }
  };

  const renderConfirmation = () => (
    <ConfirmContainer>
      <Content>
        <HeaderRegister>
          <HeadingStyled type="h2">Cadastro confirmado!</HeadingStyled>
          <Description>
            Aguarde enquanto carregamos as informações...
          </Description>
        </HeaderRegister>

        <ProgressStyled percent={percent} status="active" showInfo={false} />
      </Content>
    </ConfirmContainer>
  );

  return (
    <Layout mode={"external"}>
      <RegisterWrapper>
        <ContainerStyled>
          {!hasConfirm ? (
            <Content>
              <FormContainer>
                <GoBackButton
                  mode="action"
                  leftElement={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}
                >
                  voltar
                </GoBackButton>
                <FormContent className="scroller">
                  <HeaderRegister>
                    <HeadingStyled type="h2">Cadastro</HeadingStyled>
                    <Description>
                      Não esqueça de preencher os campos com de acordo com os
                      dados preenchidos pelo seu fornecedor.
                    </Description>
                    {showError ? 
                      !errorMessage ? (
                        <BoxAlert label="Dados em conflito. Por favor, verifique os campos assinalados." />
                      ) : (
                        <BoxAlert label={errorMessage} />
                      ) : null
                    }
                  </HeaderRegister>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                  >
                    {formik => {
                      const { values, isValid, dirty } = formik;

                      return (
                        <FormStyled>
                          <Inputfield
                            name={FieldNames.CNPJ_OR_CPF}
                            label="CPF/CNPJ de destino*"
                            type="text"
                            mask={ values.cnpjOrCpf.length > 14 ? '99.999.999/9999-99' : '999.999.999-99999' }
                            maskChar={null}
                            placeholder="Digite CPF ou CNPJ de destino"
                          />

                          <Inputfield
                            name={FieldNames.CELL_PHONE}
                            label="Celular"
                            type="text"
                            mask="(99) 99999-9999"
                            maskChar={null}
                            placeholder="Digite seu celular"
                          />

                          <Inputfield
                            name={FieldNames.MAIL_CODE}
                            label="Código enviado por e-mail*"
                            tooltipLabel="Insira o código de 6 dígitos que foi enviado para seu celular e seu e-mail."
                            type="text"
                            placeholder="Digite o código de ativação"
                            maxLength={6}
                          />

                          <CheckboxStyled
                            onChange={(e: any) => setCheckTerms(e.target.checked)}
                            hasChecked={checkTerms}
                          >
                            Estou de acordo com os{' '}
                            <a href="/">termos e políticas de privacidade</a>
                          </CheckboxStyled>

                          <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                            onChange={(value: any) =>{
                              setReCaptcha(`${value}`);
                            }}
                          />

                          <ButtonStyled
                            mode="default"
                            htmlType="submit"
                            disabled={
                              !(dirty && isValid && checkTerms && reCaptcha)
                            }
                          >
                            Entrar
                          </ButtonStyled>
                        </FormStyled>
                      );
                    }}
                  </Formik>
                  <FooterStyled show mode="external" />
                </FormContent>
                {/* {scroll ? null : <FadeBottom />} */}
              </FormContainer>
            </Content>
          ) : (
            renderConfirmation()
          )}
          <FooterStyled show={hasConfirm} isMobile mode="external" />
        </ContainerStyled>
      </RegisterWrapper>
    </Layout>
  );
};

export default RegisterForm;

const initialValues = {
  [FieldNames.CNPJ_OR_CPF]: '',
  [FieldNames.CELL_PHONE]: '',
  [FieldNames.MAIL_CODE]: '',
};

const validationSchema = Yup.object().shape({
  [FieldNames.CNPJ_OR_CPF]: Yup.string()
    .required('Campo obrigatório')
    .test('validate-cpnj-or-cpf', 'CPF/CNPJ inválido',
      function(value: any) {
        let isValidCNPJ, isValidCPF;
        if(value?.length > 14) { 
          isValidCNPJ = validateCnpj(value) ;
        } else {
          isValidCPF = validateCpf(value);
        }

        if (!isValidCNPJ && !isValidCPF) return false;
        return true;
    }),
  [FieldNames.CELL_PHONE]: Yup.string()
    .test('validate-phone', 'Número de telefone não é válido', validatePhone),
  [FieldNames.MAIL_CODE]: Yup.string()
    .required('Campo obrigatório')
    .test('validate-code', 'Insira um código válido', validateConfirmationCode),
});
