import styled from 'styled-components';

import { Form } from 'formik';

import Text from '../../../../components/text';
import Button from '../../../../components/button';
import Heading from '../../../../components/heading';
import Container from '../../../../components/container';

export const ConsultWrapper = styled.div``;

export const ContainerStyled = styled(Container)``;

export const Content = styled.div`
  width: 48vw;
  max-width: 504px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    width: 100%;
    max-width: unset;
  `}
`;

export const FormContainer = styled.div`
  padding-top: 20px;

  ${({ theme }) => theme.media.md`
    padding-top: 60px;
  `}
`;

export const GoBackButton = styled(Button)`
  padding-bottom: 24px;
`;

export const FormContent = styled.div`
  height: calc(100vh - 14em);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-moz-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.md`
    height: calc(100vh - 15em);
  `}
`;

export const HeaderConsult = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 30px;
`;

export const HeadingStyled = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled(Text)`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey.darkness_rgba};
  border-radius: 8px;
  padding: 16px 24px;

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
  }
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 8px;
  height: 48px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;
