import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/layout';

import {
  Content,
  ButtonStyled,
  HeadingStyled,
  HomeWrapper,
  ButtonOutlined,
  ButtonContent,
  ContainerStyled,
} from './styles';

const Home = () => {
  const [animated, setAnimated] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setAnimated(true);
    setTimeout(() => {
      navigate('/consult');
    }, 500);
  };

  return (
    <Layout mode={"external"}>
      <HomeWrapper>
        <ContainerStyled>
          <Content animated={animated}>
            <HeadingStyled type="h1">Tracking Luft</HeadingStyled>
            <HeadingStyled type="h2">
              Rastreio de pedidos em tempo real
            </HeadingStyled>

            <ButtonContent>
              <ButtonStyled mode="default" onClick={handleChange}>
                Rastrear uma nota
              </ButtonStyled>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.luft.com.br/"
              >
                <ButtonOutlined mode="outline">Conheça a Luft</ButtonOutlined>
              </a>
            </ButtonContent>
          </Content>
        </ContainerStyled>
      </HomeWrapper>
    </Layout>
  );
};

export default Home;
