import styled from 'styled-components';

import { Steps } from 'antd';

import theme from '../../../../../../constants/theme';

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;

  padding-right: 0.4rem;
`;

export const ActivePulse = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;

  background: #0da6f2;
  box-shadow: 0 0 0 rgba(13, 166, 242, 0.4);

  animation: pulse 1.8s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(13, 166, 242, 0.4);
    }

    70% {
      box-shadow: 0 0 0 12px rgba(13, 166, 242, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(13, 166, 242, 0);
    }
  }
`;

export const AntdStepsStyled = styled(Steps)`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${theme.colors.blue.midle};
    font-weight: 500;
  }

  .ant-steps-item-subtitle {
    color: ${theme.colors.grey.darkness_mid};
    font-weight: 500;
  }

  @media (max-width: 540px) {
    height: 350px;
  }
`;
