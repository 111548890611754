function Created({ ...props }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7261 15.0016L23.5661 15.0015C23.8058 15.0014 24.0002 14.8071 24.0002 14.5674C24.0002 14.3277 23.8058 14.1333 23.5661 14.1333L18.726 14.1335C18.4863 14.1335 18.292 14.3278 18.292 14.5675C18.292 14.8073 18.4863 15.0016 18.7261 15.0016Z" fill="#757575"/>
      <path d="M16.4342 15.0016H16.7042C16.9439 15.0016 17.1382 14.8073 17.1382 14.5675C17.1382 14.3278 16.9439 14.1335 16.7042 14.1335H16.4342C16.1945 14.1335 16.0002 14.3278 16.0002 14.5675C16.0002 14.8073 16.1945 15.0016 16.4342 15.0016Z" fill="#757575"/>
      <path d="M18.7261 19.2339L23.5661 19.2338C23.8058 19.2338 24.0002 19.0395 24.0002 18.7998C24.0002 18.56 23.8058 18.3657 23.5661 18.3657L18.726 18.3658C18.4863 18.3659 18.292 18.5602 18.292 18.7999C18.292 19.0396 18.4863 19.234 18.7261 19.2339Z" fill="#757575"/>
      <path d="M16.4342 19.234H16.7042C16.9439 19.234 17.1382 19.0396 17.1382 18.7999C17.1382 18.5602 16.9439 18.3659 16.7042 18.3659H16.4342C16.1945 18.3659 16.0002 18.5602 16.0002 18.7999C16.0002 19.0396 16.1945 19.234 16.4342 19.234Z" fill="#757575"/>
      <path d="M18.7261 21.3504L23.5661 21.3503C23.8058 21.3503 24.0002 21.156 24.0002 20.9162C24.0002 20.6765 23.8058 20.4822 23.5661 20.4822L18.726 20.4823C18.4863 20.4823 18.292 20.6767 18.292 20.9164C18.292 21.1561 18.4863 21.3504 18.7261 21.3504Z" fill="#757575"/>
      <path d="M16.4342 21.3504H16.7042C16.9439 21.3504 17.1382 21.1561 17.1382 20.9164C17.1382 20.6767 16.9439 20.4823 16.7042 20.4823H16.4342C16.1945 20.4823 16.0002 20.6767 16.0002 20.9164C16.0002 21.1561 16.1945 21.3504 16.4342 21.3504Z" fill="#757575"/>
      <path d="M21.281 23.4668L18.7261 23.4669C18.4864 23.4669 18.292 23.2726 18.292 23.0328C18.292 22.7931 18.4863 22.5988 18.726 22.5988L21.2809 22.5987C21.5207 22.5987 21.715 22.793 21.715 23.0327C21.715 23.2724 21.5207 23.4668 21.281 23.4668Z" fill="#757575"/>
      <path d="M16.7042 23.4669H16.4342C16.1945 23.4669 16.0002 23.2726 16.0002 23.0329C16.0002 22.7931 16.1945 22.5988 16.4342 22.5988H16.7042C16.9439 22.5988 17.1382 22.7931 17.1382 23.0329C17.1382 23.2726 16.9439 23.4669 16.7042 23.4669Z" fill="#757575"/>
      <path d="M23.5661 17.1179L18.7261 17.1181C18.4863 17.1181 18.292 16.9237 18.292 16.684C18.292 16.4443 18.4863 16.25 18.726 16.25L23.5661 16.2498C23.8058 16.2498 24.0002 16.4442 24.0002 16.6839C24.0002 16.9236 23.8058 17.1179 23.5661 17.1179Z" fill="#757575"/>
      <path d="M16.7042 17.1181H16.4342C16.1945 17.1181 16.0002 16.9237 16.0002 16.684C16.0002 16.4443 16.1945 16.25 16.4342 16.25H16.7042C16.9439 16.25 17.1382 16.4443 17.1382 16.684C17.1382 16.9237 16.9439 17.1181 16.7042 17.1181Z" fill="#757575"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5064 26.0667C16.6537 26.0667 16.7731 26.1861 16.7731 26.3334V28.4163L19.5185 26.1285C19.5664 26.0886 19.6268 26.0667 19.6892 26.0667H25.0668C25.4718 26.0667 25.8002 25.7384 25.8002 25.3334V12.5333C25.8002 12.1283 25.4718 11.8 25.0668 11.8H14.9335C14.5285 11.8 14.2002 12.1283 14.2002 12.5333V25.3334C14.2002 25.7384 14.5285 26.0667 14.9335 26.0667H16.5064ZM16.5625 29.72C16.302 29.9371 15.9064 29.7518 15.9064 29.4127V26.9333H14.9335C14.0498 26.9333 13.3335 26.217 13.3335 25.3334V12.5333C13.3335 11.6497 14.0498 10.9333 14.9335 10.9333H25.0668C25.9505 10.9333 26.6668 11.6497 26.6668 12.5333V25.3334C26.6668 26.217 25.9505 26.9333 25.0668 26.9333H19.9064L16.5625 29.72Z" fill="#757575"/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#9E9E9E"/>
    </svg>
  );
}

export default Created;
