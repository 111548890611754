import React, { useRef } from 'react'
import GoogleMapReact from 'google-map-react';

import Marker, { MarkerTypes } from './components/Marker';

interface MapProps {
  data?: {
    initial: {
      lat: number;
      lng: number;
    }
    destinationMarker?: {
      lat: number;
      lng: number;
    }
    truckMarker?: {
      lat: number;
      lng: number;
    }
  };
}

export default function Map ({ data }: MapProps) {
  const mapRef = useRef<any>();

  const optionsMap = () => {
    const defaultOptions = {
      styles: [
        { 
          elementType: "labels", 
          featureType: "poi", 
          stylers: [{ visibility: "off" }], 
        },
        { 
          elementType: "labels.icon", 
          featureType: "transit", 
          stylers: [{ visibility: "off" }], 
        }
      ]
    }
    if (window.innerWidth < 1023) {
      return {
        zoomControl: false,
        fullscreenControl: false,
        ...defaultOptions
      };
    }

    return {
      fullscreenControl: false,
      ...defaultOptions
    };
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDNV7mHyjuTm0VC8ZZnW90OTZWrvBnXIaw&v' }}
      center={data?.initial}
      defaultZoom={13}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      options={optionsMap}
    >
      {data?.destinationMarker && (
        <Marker lat={data?.destinationMarker.lat} lng={data?.destinationMarker.lng} type={MarkerTypes.Destination}/>
      )}
      {data?.truckMarker && (
        <Marker lat={data?.truckMarker.lat} lng={data?.truckMarker.lng} type={MarkerTypes.Trucker}/>
      )}
    </GoogleMapReact>
  )
}
