import { STEP_COLUMN, STEP_TMS } from '../constants/stepTracking';

export default function ConvertSteps(current: number) {
  switch(current) {
    case STEP_TMS.EmSeparacao:
    case STEP_TMS.AguardandoLancamentoNf:
      return STEP_COLUMN.CREATED;
    case STEP_TMS.EntregaDeTransferencia:
    case STEP_TMS.AguardandoEmbarque:
      return STEP_COLUMN.PREPARED;
    case STEP_TMS.EntregaTransportadora:
    case STEP_TMS.EntregaAgendada:
    case STEP_TMS.EmTransito:
      return STEP_COLUMN.IN_TRANSIT;
    case STEP_TMS.ViagemDeEntrega:
      return STEP_COLUMN.ON_THE_WAY;
    case STEP_TMS.EntregaComAtraso:
    case STEP_TMS.Entregue:
      return STEP_COLUMN.DELIVERED;
    case STEP_TMS.EntregaParcial:
      return STEP_COLUMN.PARTIALLY_DELIVERED;
    case STEP_TMS.RecusaTotal:
      return STEP_COLUMN.DENIED_DELIVERED;
    case STEP_TMS.BaixadoSemEntrega:
    case STEP_TMS.Cancelado:
      return STEP_COLUMN.CANCELED_DELIVERED;
    default:
      return STEP_COLUMN.IGNORE;
  }
}