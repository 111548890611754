import styled, { css } from 'styled-components';

const common = css`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  transition: ease 0.2s;
`;

export const Heading1 = styled.h1`
  ${common};
  font-size: 48px;
  line-height: 56px;

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 40px;
  `}
`;

export const Heading2 = styled.h2`
  ${common};
  font-size: 24px;
  line-height: 28px;

  ${({ theme }) => theme.media.md`
    font-size: 18px;
    line-height: 24px;
  `}
`;
