import { FC } from 'react';

import { Col, Row } from 'antd';

import { 
  Forecast, 
  VolumeWrapper, 
  ForecastWrapper, 
  ContentSubtitle, 
  RecipientWrapper,
  ContentDescription, 
  DestinationWrapper,
} from './styles';

import { nfStatus } from '../../index';

import { RemoveLeadingZeros, RemoveSpaces } from '../../../../../../utils/StringConvert';

interface OrderStatusProps {
  sender: string;
  volumes: string;
  address: string;
  forecast: string;
  recipient?: string;
  status: number;
  nfStatus?: Array<nfStatus>;
  nfs?: Array<string>;
}

const OrderStatus: FC<OrderStatusProps> = ({ 
  status,
  sender, 
  volumes,
  address, 
  forecast, 
  recipient,
  nfStatus,
  nfs,
}) => {

  return (
    <Row>
      <Col sm={24} md={10}>
        {status === 4 || status === 5 ? (
          <div>
            <ForecastWrapper>
              <ContentSubtitle>Entregue em</ContentSubtitle>
              <Forecast>{forecast}</Forecast>
            </ForecastWrapper>
            {recipient && (
              <RecipientWrapper>
                <ContentSubtitle>Recebido por</ContentSubtitle>
                <ContentDescription>{recipient}</ContentDescription>
              </RecipientWrapper>
            )}
          </div>
        ) : (
          <ForecastWrapper>
            <ContentSubtitle>Previsão de entrega</ContentSubtitle>
            <Forecast>{forecast}</Forecast>
          </ForecastWrapper>
        )}
      </Col>
      <Col sm={24} md={14}>
        <Row>
          <Col span={12}>
            <ContentSubtitle>Enviado por</ContentSubtitle>
            <ContentDescription>{sender}</ContentDescription>
          </Col>
          <Col span={12}>
            <VolumeWrapper>
              <ContentSubtitle>Volumes</ContentSubtitle>
              <ContentDescription>{volumes}</ContentDescription>
            </VolumeWrapper>
          </Col>
        </Row>
        {nfs && (
          <Row>
            <Col span={24}>
              <DestinationWrapper>
                <ContentSubtitle>Notas fiscais</ContentSubtitle>
                <ContentDescription>{nfs.join(', ')}</ContentDescription>
              </DestinationWrapper>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <DestinationWrapper>
              <ContentSubtitle>Destino</ContentSubtitle>
              <ContentDescription>{address}</ContentDescription>
            </DestinationWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OrderStatus;
