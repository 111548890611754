import { ThemeProvider } from 'styled-components';

import theme from './constants/theme';

import AppContext from './contexts';
import AppRoutes from './routes';

function App() {
  return (
    <AppContext>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </AppContext>
  );
}

export default App;
