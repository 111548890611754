import styled, { css } from 'styled-components';

import { Tooltip } from 'antd';

import InputMask from 'react-input-mask';

import { QuestionCircleOutlined } from '@ant-design/icons';
interface InputStyledProps {
  error?: any;
  disabled?: boolean;
  hasContent?: boolean;
}

export const Wrapper = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grey.light};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const TooltipStyled = styled(Tooltip)`
  margin-left: 6.88px;
  display: flex;
  align-items: center;
`;

export const QuestionStyled = styled(QuestionCircleOutlined)`
  color: ${({ theme }) => theme.colors.blue.light};
  width: 14px;
`;

export const InputContainer = styled.div<InputStyledProps>`
  height: 48px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey.lighting : 'none'};
  border: 1px solid;
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey.lightness : theme.colors.blue.light};
  border-radius: 8px;
  padding: 12px 20px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.yellow.mid};
    `}
`;

const baseInputStyles = css<InputStyledProps>`
  width: 100%;
  border: none;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  background: none;
  color: ${({ theme }) => theme.colors.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blue.midless};
  }

  ${({ error }) =>
    error &&
    css`
      &::placeholder {
        color: ${({ theme }) => theme.colors.yellow.light_rgba};
      }
    `}
`;

export const Input = styled.input<InputStyledProps>`
  ${baseInputStyles}
`;

export const StyledMaskedInput = styled(InputMask)<InputStyledProps>`
  ${baseInputStyles}
`;

export const TextError = styled.span<InputStyledProps>`
  display: block;
  margin-top: 16px;

  ${({ hasContent }) =>
    hasContent &&
    css`
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.yellow.mid};
      display: block;
      margin-top: 4px !important;
    `}
`;
