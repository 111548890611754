/* eslint-disable no-useless-escape */
import { isCnpjValid } from './cnpj-validator';

export const validatePhone = (value: string | undefined) => {
  const phoneRegex = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;

  let isValid;
  if (!value || value === "") return true;
  
  if (value) isValid = phoneRegex.test(value);

  if (!isValid) return false;
  return true;
};

export const validateConfirmationCode = (value: string | undefined) => {
  const codeRegex = /^[0-9]{6}$/;

  let isValid;
  if (value) isValid = codeRegex.test(value);

  if (!isValid) return false;
  return true;
};

export const validateEmail = (value: string | undefined) => {
  const emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  let isValid;
  if (value) isValid = emailRegex.test(value);

  if (!isValid) return false;
  return true;
};

export const validateCpf = (value: string) => {
  // return valid if empty
  if (!value) return false;

  // remove non-digits from CPF
  const cpf = value.replace(/\D/g, '');

  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999' ||
    cpf === '12345678909'
  ) {
    return false;
  }

  let add = 0;
  let rev;

  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9), 10)) return false;

  add = 0;
  for (let j = 0; j < 10; j++) {
    add += parseInt(cpf.charAt(j), 10) * (11 - j);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10), 10)) return false;

  return true;
};

export const validateCnpj = isCnpjValid;
