import styled, { css } from 'styled-components';
import { Button } from 'antd';

const common = css`
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  transition: ease 0.2s;
`;

export const StyledButton = styled(Button)`
  ${common}
  color: ${({ theme }) => theme.colors.blue.dark};
  background: ${({ theme }) => theme.colors.blue.mid};
  border: none;

  &:hover {
    background: ${({ theme }) => theme.colors.blue.light};
    color: ${({ theme }) => theme.colors.blue.dark};
  }
  
  &:focus {
    background: ${({ theme }) => theme.colors.blue.mid};
    color: ${({ theme }) => theme.colors.blue.dark};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.blue.dark_gray_rgba};
    color: ${({ theme }) => theme.colors.grey.light_rgba};
    &:hover {
      background: ${({ theme }) => theme.colors.blue.dark_gray_rgba};
    }
  }
`;

export const OutlineButton = styled(Button)`
  ${common}
  color: ${({ theme }) => theme.colors.blue.light};
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.blue.light};

  &:hover {
    background: ${({ theme }) => theme.colors.blue.dark_rgba};
    color: ${({ theme }) => theme.colors.blue.light};
  }

  &:focus {
    background: none;
    border: 1px solid ${({ theme }) => theme.colors.blue.light};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.blue.dark_gray_mid_rgba};
    color: ${({ theme }) => theme.colors.blue.dark_gray_light_rgba};
    background: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.blue.dark_gray_mid_rgba};
      background: none;
    }
  }
`;

export const ActionButton = styled(Button)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue.light};
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;

  &:hover {
    background: none;
  }
`;