import styled from 'styled-components';

export const TextStyled = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  transition: ease 0.2s;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    line-height: 22px;
  `}
`;
