import React from 'react';

import { TruckMap, DestinationMap } from '../../../icons';

import { Container } from './styles';

export enum MarkerTypes{
  Trucker,
  Destination,
}

interface MarkerProps {
  lat: number;
  lng: number;
  type: MarkerTypes;
}

const Marker: React.FC<MarkerProps> = ({type}) => {
  return (
    <Container>
      {type === MarkerTypes.Trucker ? (
        <TruckMap height='40' width='36'/>
      ) : (
        <DestinationMap height='38' width='32'/>
      )}
    </Container>
  );
}

export default Marker;