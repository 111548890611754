import React from 'react';

import Container from '../../../../../components/container';
import Layout from '../../../../../components/layout';
import CardList from '../../../components/CardList';

import {
  ArrowUpIcon,
  ButtonSearch,
  ButtonStyled,
  ContainerStyled,
  HeaderStyled,
  HeadingStyled,
  TextStyled,
  ResultContainer,
  ResultSearchText,
  SearchContainer,
  SearchIcon,
  SearchStyled,
  Wrapper,
} from './styles';

import { IInvoice } from '../index';

interface NFListLayoutProps {
  loading: boolean;
  showResult: boolean;
  allOrders: Array<IInvoice>;
  countOrders: number;
  searchParams?: string; 
  showMobileSearch: boolean;
  handleOpen: () => void;
  handleClearSearch: () => void;
  handleGetCTEs: (filter?: string) => void;
}

const NFListLayout: React.FC<NFListLayoutProps> = ({
  loading,
  allOrders,
  showResult,
  countOrders,
  searchParams,
  showMobileSearch,
  handleOpen,
  handleGetCTEs,
  handleClearSearch
}) => {

  const handleClearSearchParameters = () => {
    handleClearSearch();
  }

  return (
    <Layout mode={"internal"}>
      <Wrapper>
        <ContainerStyled>
          <HeaderStyled>
            <div>
              <HeadingStyled type="h2">Lista de entregas <TextStyled>CTE’s</TextStyled></HeadingStyled>
              <SearchStyled
                placeholder="Busque por nota ou CTE"
                onSearch={(value: string) => handleGetCTEs(value)}
                enterButton
              />

              <ButtonSearch mode="action" onClick={handleOpen}>
                {showMobileSearch === true ? <ArrowUpIcon /> : <SearchIcon />}
              </ButtonSearch>
            </div>
            {searchParams ? null : showMobileSearch ? (
              <SearchContainer>
                <SearchStyled
                  placeholder="Busque por nota ou CTE"
                  onSearch={(value: string) => handleGetCTEs(value)}
                  enterButton
                  isMobile
                />
              </SearchContainer>
            ) : null}

            {searchParams &&
              <ResultContainer showResult={showResult}>
                <ResultSearchText>
                  Resultados da pesquisa: <strong>"{searchParams}"</strong>
                </ResultSearchText>

                <ButtonStyled mode="action" onClick={handleClearSearchParameters}>
                  Limpar X
                </ButtonStyled>
              </ResultContainer>
            }
          </HeaderStyled>
        </ContainerStyled>
        <Container>
          <CardList 
            data={allOrders} 
            count={countOrders} 
            loading={loading} 
            handleGetCTEs={handleGetCTEs}
          />
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default NFListLayout;
