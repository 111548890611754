import { FC, ReactNode } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import { BgCut, BgGradient, BgImage, MaskImage } from './styles';

interface BGProps {
  children: ReactNode;
  bgOpacity?: boolean;
}

const Background: FC<BGProps> = ({ bgOpacity, children }) => {
  const { height } = useWindowDimensions();

  return (
    <BgImage bgOpacity={bgOpacity} maxHeight={height}>
      <MaskImage bgOpacity={bgOpacity}>
        <BgGradient bgOpacity={bgOpacity}>
          <BgCut bgOpacity={bgOpacity}>{children}</BgCut>
        </BgGradient>
      </MaskImage>
    </BgImage>
  );
};

export default Background;
