import React from 'react';
import { AuthProvider } from '../hooks/auth';
import { InvoiceProvider } from './invoicesContext';
import { NavigationProvider } from '../hooks/navigationInfos';

const AppContext: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <InvoiceProvider>
        <NavigationProvider>
          {children}
        </NavigationProvider>
      </InvoiceProvider>
    </AuthProvider>
  );
}

export default AppContext;
